/* eslint-disable react/prop-types */
import React, {useState, useEffect} from 'react';
import { withRouter } from "react-router";
import { compose, bindActionCreators } from "redux";
import { connect } from "react-redux";
import ConfirmInfoComponent from 'components/ConfirmInfo/index';
import {STATUS_CODE} from '../../constants/identify';
import {ERROR_IDENTIFY} from '../../constants/commons';
import * as identifyActions from 'actions/identify';
import { isEmpty } from 'commons/shared';

const ConfirmInfoContainer= (props)=>{
    const {handleBack, errorCMND,
        postCMNDCreators, resetErrorStep, errorFaceComparison, 
        handleErrorStep, handleNext, infoCMND, infoFaceComparison,
        handleRetryIdentify, handleErrorConfirm, handleErrorSystem
    }= props;
    const [error, setError]= useState({
        msg: '',
        type: '',
        code:''
    });
    const { fetchResetInitial, } = postCMNDCreators;

    useEffect(()=>{
        if(infoCMND){
            const checkNull= Object.values(infoCMND?.data).every((value)=> value===null);
            if(infoCMND?.result_code !==200){
                handleErrorSystem();
                setError({
                    msg: STATUS_CODE[infoCMND.result_code],
                    type: 'error',
                    code: infoCMND.result_code
                });
            } else if(checkNull){
                setError({
                    msg: 'Có lỗi khi nhận diện giấy tờ, quý khách vui lòng thực hiện lại.',
                    type: 'error'
                });
            } else {
                let allErrors= [];
                const filterInputCheck= infoCMND?.input_check?.filter((item)=> item.value===0);
                const filterFraudCheck= infoCMND?.fraud_check?.filter((item)=> item.value===0);
                const filterLogicCheck= infoCMND?.logic_check?.filter((item)=> item.value===0);
                allErrors= [...allErrors, filterInputCheck, filterFraudCheck, filterLogicCheck];
                allErrors= allErrors.flat();
                if(!isEmpty(allErrors)){
                    handleErrorConfirm();
                    const itemError= ERROR_IDENTIFY.find((item)=> item.code=== allErrors[0].code && item.key=== allErrors[0].key);
                    if(itemError){
                        // setError({
                        //     msg: itemError.text,
                        //     title: itemError.title,
                        //     type: 'warning'
                        // });
                    }
                }
            }
        }
    }, [infoCMND]);

    const handleBackRedo= ()=>{
        handleBack();
        resetErrorStep();
        handleRetryIdentify();
        if(fetchResetInitial){
            fetchResetInitial();
        }
    };

    const handleClickFinish = () => {
        handleNext();
        if (fetchResetInitial) {
            fetchResetInitial();
        }
    };

    useEffect(()=>{
        if( errorFaceComparison || (infoFaceComparison?.face_compare === 0 && typeof infoFaceComparison?.face_compare!== "undefined" 
             && infoFaceComparison?.face_compare !== 1)){
            handleErrorStep();
        }
    }, [errorFaceComparison, infoFaceComparison]);

    useEffect(()=> {
        if(errorCMND){
            setError({
                msg: "Có lỗi hệ thống xảy ra, quý khách vui lòng thực hiện lại quá trình eKYC",
                type: 'error'
            });
        }
    }, [errorCMND]);

    return <ConfirmInfoComponent handleBackRedo={handleBackRedo} resetErrorStep
        handleClickFinish= {handleClickFinish}
        error={error} {...props} />;
};

const mapStateToProps = (state) => ({
    infoCMND: state.identify.infoCMND,
    errorCMND: state.identify.errorCMND,
    isPostingCMND: state.identify.isPostingCMND,
    isPostingFaceComparison: state.identify.isPostingFaceComparison,
    infoFaceComparison: state.identify.infoFaceComparison,
    errorFaceComparison: state.identify.errorFaceComparison,
});

const mapDispatchToProps = (dispatch) => ({
    postCMNDCreators: bindActionCreators(identifyActions, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withRouter, withConnect)(ConfirmInfoContainer);