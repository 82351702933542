/* eslint-disable react/prop-types */
import React from 'react';
import Instruction from './instructor2';
import Camera from './camera';

const IdentifyComponent= (props) => {
    const {instructor, handleNextCamera}= props;
    return (
        <div>
            {instructor && <Instruction handleNextCamera={handleNextCamera} {...props} />}
            {!instructor && <Camera {...props} /> }
        </div>
    );
};

export default IdentifyComponent;
