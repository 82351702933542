import * as identifyConstant from "../constants/identify";

const initialState = {
    isPostingCMND: false,
    infoCMND: {},
    errorCMND: null,
};
const stateDefault = {
    isPostingCMND: false,
    infoCMND: {},
    errorCMND: null,
};

const identifyReducer = (state = initialState, action) => {
    switch (action.type) {
    case identifyConstant.FETCH_POST_CMND: {
        return {
            ...state,
            isPostingCMND: true,
            errorCMND: null,
            status: stateDefault,
        };
    }
    case identifyConstant.FETCH_POST_CMND_SUCCESS: {
        const { data } = action.payload;
        return {
            ...state,
            isPostingCMND: false,
            errorCMND: null,
            status: { ...state.status },
            infoCMND: data,
        };
    }
    case identifyConstant.FETCH_POST_CMND_FAILED: {
        const { error } = action.payload;
        return {
            ...state,
            isPostingCMND: false,
            errorCMND: error,
            status: { ...state.status, isPostingCMND: false },
        };
    }
    default:
        return {...state};
    }
};

export default identifyReducer;