import React from 'react';
import {AppBar, Toolbar} from '@material-ui/core';
import logoImg from 'assets/images/logo.png';

const HeaderComponent= (props)=>{
    return (
        <AppBar position="fixed" style={{backgroundColor:"white", height:"60px"}}>
            <Toolbar>
                <img src={logoImg} alt="logo" style={{height:10}} />
            </Toolbar>
        </AppBar>
    );
};

export default HeaderComponent;