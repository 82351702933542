/** check identifier */
export const FETCH_POST_CMND= 'FETCH_POST_CMND';
export const FETCH_POST_CMND_SUCCESS= 'FETCH_POST_CMND_SUCCESS';
export const FETCH_POST_CMND_FAILED= 'FETCH_POST_CMND_FAILED';

/** reset initial */
export const RESET_INITIAL= 'RESET_INITIAL';

export const STATUS_CODE= {
    408: 'Có lỗi hệ thống xảy ra, quý khách vui lòng thực hiện lại quá trình eKYC',
    401: 'Có lỗi hệ thống xảy ra, quý khách vui lòng thực hiện lại quá trình eKYC',
    413: 'Ảnh đầu vào quá lớn',
    500: 'Có lỗi khi nhận diện giấy tờ, quý khách vui lòng thực hiện lại lại.',
    605: 'Có lỗi hệ thống xảy ra, quý khách vui lòng thực hiện lại quá trình eKYC',
    301: 'Không tìm thấy khuôn mặt trên ảnh mặt trước CMND/CCCD',
    302: 'Không tìm thấy khuôn mặt trên ảnh chân dung liveness',
    600: 'Có lỗi hệ thống xảy ra, quý khách vui lòng thực hiện lại quá trình eKYC',
};