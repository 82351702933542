import * as faceConstant from "../constants/face";

const initialState = {
    isPostingFaceComparison: false,
    infoFaceComparison: {},
    errorFaceComparison: null,
};
const stateDefault = {
    isPostingFaceComparison: false,
    infoFaceComparison: {},
    errorFaceComparison: null,
};

const faceReducer = (state = initialState, action) => {
    switch (action.type) {
    case faceConstant.FETCH_POST_FACE_COMPARISON: {
        return {
            ...state,
            isPostingFaceComparison: true,
            errorFaceComparison: null,
            status: stateDefault,
        };
    }
    case faceConstant.FETCH_POST_FACE_COMPARISON_SUCCESS: {
        const { data } = action.payload;
        return {
            ...state,
            isPostingFaceComparison: false,
            errorFaceComparison: null,
            status: { ...state.status },
            infoFaceComparison: data,
        };
    }
    case faceConstant.FETCH_POST_FACE_COMPARISON_FAILED: {
        const { error } = action.payload;
        return {
            ...state,
            isPostingFaceComparison: false,
            errorFaceComparison: error,
            status: { ...state.status, isPostingFaceComparison: false },
        };
    }
    default:
        return {...state};
    }
};

export default faceReducer;