/* eslint-disable react/prop-types */
import React from 'react';
import { useStyles, BootstrapButton } from './styles';
import {
    Grid, Paper, Typography, Box, TextField, Dialog,
    DialogContent, DialogContentText
} from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { assets } from '../../assets';
import ColRightLayout from '../ColRightLayout';
import { colors } from 'constants/colors';

const ConfirmInfoComponent = (props) => {
    const { infoCMND, imgCMND1,
        imgCMND2, imgLive,
        handleClickFinish, handleClickBackFaceCompare,
        imgDialog, imgDialogOpen,
        handleClickImage, handlImgDialogClose } = props;
    const classes = useStyles();
    const baseTheme = createTheme();
    const isMobile = useMediaQuery(baseTheme.breakpoints.down('xs'), {
        defaultMatches: true
    });
    return (
        <ThemeProvider theme={baseTheme}>
            <Box>
                <Grid container direction='row' justifyContent='center'>
                    <Grid item xs={12} sm={12} md={1} lg={2} className={classes.gridItemSecondNone}>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItemFirst}>
                        <Paper className={classes.paperFirst}>
                            <div className={classes.cameraDiv}>
                                <div style={{ flex: 1 }}>
                                    <Typography className={classes.textTitle}>Kết quả OCR</Typography>
                                </div>
                                {/* <div style={{ flex: 1 }}>
                                    <Typography className={classes.textIdentify}>Thông tin eKYC</Typography>
                                </div> */}
                                <div style={{ flex: 8 }}>
                                    <Grid container spacing={isMobile ? 0 : 3} className={classes.textFieldContainer}>
                                        <Grid item sm={6}>
                                            <TextField fullWidth
                                                className={classes.textField}
                                                id="filled1"
                                                label="Số CMND/CCCD"
                                                defaultValue={infoCMND?.data && infoCMND?.data?.id}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="filled"
                                            />
                                            <TextField fullWidth
                                                className={classes.textField}
                                                id="filled2"
                                                label="Họ và tên"
                                                defaultValue={infoCMND?.data && infoCMND?.data?.name}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="filled"
                                            />
                                            <TextField fullWidth
                                                className={classes.textField}
                                                id="sex"
                                                label="Giới tính"
                                                defaultValue={infoCMND?.data && infoCMND?.data?.sex}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="filled"
                                            />
                                            <TextField fullWidth
                                                className={classes.textField}
                                                multiline
                                                id="address"
                                                label="Địa chỉ"
                                                defaultValue={infoCMND?.data && infoCMND?.data?.address}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="filled"
                                            />
                                            <TextField fullWidth
                                                className={classes.textField}
                                                multiline
                                                id="issue_at"
                                                label="Nơi cấp"
                                                defaultValue={infoCMND?.data && infoCMND?.data?.issue_at}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="filled"
                                            />
                                            <TextField fullWidth
                                                className={classes.textField}
                                                id="religion"
                                                label="Tôn giáo"
                                                defaultValue={infoCMND?.data && infoCMND?.data?.religion || 'Không'}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="filled"
                                            />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <TextField fullWidth
                                                className={classes.textField}
                                                id="filled3"
                                                label="Ngày cấp"
                                                defaultValue={infoCMND?.data && infoCMND?.data?.issue_date}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="filled"
                                            />
                                            <TextField fullWidth
                                                className={classes.textField}
                                                id="filled4"
                                                label="Ngày sinh"
                                                defaultValue={infoCMND?.data && infoCMND?.data?.birthday}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="filled"
                                            />
                                            <TextField fullWidth
                                                className={classes.textField}
                                                id="province"
                                                label="Quê quán"
                                                defaultValue={infoCMND?.data && infoCMND?.data?.province}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="filled"
                                            />
                                            <TextField fullWidth
                                                className={classes.textField}
                                                id="expiry"
                                                label="Ngày hết hạn"
                                                defaultValue={infoCMND?.data && infoCMND?.data?.expiry}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="filled"
                                            />
                                            <TextField fullWidth
                                                className={classes.textField}
                                                id="ethnicity"
                                                label="Dân tộc"
                                                defaultValue={infoCMND?.data && infoCMND?.data?.ethnicity || 'Kinh'}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="filled"
                                            />
                                            <TextField fullWidth
                                                className={classes.textField}
                                                id="district"
                                                label="Quận huyện"
                                                defaultValue={infoCMND?.data && infoCMND?.data?.district}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="filled"
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                <div style={{ flex: 1 }}>
                                    <div style={{ display: "flex", justifyContent: 'center', paddingBottom: 20 }}>
                                        {/* <BootstrapButton className={classes.btnBack} onClick={handleClickBackFaceCompare}>
                                            Quay lại
                                        </BootstrapButton> */}
                                        <BootstrapButton className={classes.btnFinish} onClick={handleClickFinish}>
                                            Kết Thúc
                                        </BootstrapButton>
                                    </div>
                                </div>
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={2} className={classes.gridItemSecond}>
                        <ColRightLayout
                            imageFront={imgCMND1 && imgCMND1?.preview}
                            imageBack={imgCMND2 && imgCMND2?.preview}
                            imageSelfie={imgLive && imgLive?.preview}
                            verifyFront={true}
                            verifyBack={true}
                            handleClickImage={handleClickImage}
                            valueProgress={99}
                        />
                    </Grid>
                </Grid>
                {/* dialog img */}
                <Dialog className={classes.dialog}
                    open={imgDialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <div className={classes.divCancelIcon} onClick={handlImgDialogClose}>
                        <img alt="front" src={assets?.iconClose} className={classes.cancelIcon} />
                    </div>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText id="alert-dialog-description">
                            <img alt="front" src={imgDialog} className={classes.imgShow} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>
            </Box>
        </ThemeProvider>
    );
};

export default ConfirmInfoComponent;