import { getRandom, randomStep } from '../commons/shared';
import left from 'assets/images/left.png';
import right from 'assets/images/right.png';
import blink from 'assets/images/blink.png';
import smile from 'assets/images/smile.png';
import straight from 'assets/images/straight.png';
import * as _ from 'lodash';
import { Svg } from 'assets/svg';

export const TIMEOUT = 180000;
export const TIME_STEP = 2000;
export const TIME_INTERVAL = 200;
export const TOTAL_TIME = 180;


// export const LIVENESS_STEPS = [
//     { name: "left", img: left, text: "Quay mặt qua trái", layer:'leftLayer' },
//     { name: "right", img: right, text: "Quay mặt sang phải", layer:'rightLayer' },
//     { name: "blink", img: blink, text: "Nháy mắt", layer:'straightLayer' },
//     { name: "smile", img: smile, text: "Mỉm cười", layer:'straightLayer' },
//     { name: "straight", img: straight, text: "Giữ mặt thẳng 3s", layer:'straightLayer' }
// ];

// export const LIVENESS_STEPS_TWO = [
//     { name: "left", img: left, text: "Quay mặt qua trái", layer:'leftLayer' },
//     { name: "right", img: right, text: "Quay mặt sang phải", layer:'rightLayer' },
// ];

// export const LIVENESS_STEPS_TWO_OPTION = [
//     { name: "blink", img: blink, text: "Nháy mắt", layer:'straightLayer' },
//     { name: "smile", img: smile, text: "Mỉm cười", layer:'straightLayer' },
// ];

export const LIVENESS_STEPS = [
    { name: "left", img: Svg.LeftIcon, text: "Quay mặt qua trái", layer: 'leftLayer' },
    { name: "right", img: Svg.RightIcon, text: "Quay mặt sang phải", layer: 'rightLayer' },
    { name: "blink", img: Svg.BlinkIcon, text: "Nháy mắt", layer: 'straightLayer' },
    { name: "smile", img: Svg.SmileIcon, text: "Mỉm cười", layer: 'straightLayer' },
    { name: "straight", img: Svg.StraightIcon, text: "Giữ mặt thẳng 3s", layer: 'straightLayer' }
];

export const LIVENESS_STEPS_TWO = [
    { name: "left", img: Svg.LeftIcon, text: "Quay mặt qua trái", layer: 'leftLayer' },
    { name: "right", img: Svg.RightIcon, text: "Quay mặt sang phải", layer: 'rightLayer' },
];

export const LIVENESS_STEPS_TWO_OPTION = [
    { name: "blink", img: Svg.BlinkIcon, text: "Nháy mắt", layer: 'straightLayer' },
    { name: "smile", img: Svg.SmileIcon, text: "Mỉm cười", layer: 'straightLayer' },
];

const RANDOM_TWO_STEP = LIVENESS_STEPS_TWO[_.random(LIVENESS_STEPS_TWO.length - 1)];
const RANDOM_TWO_STEP_OPTION = LIVENESS_STEPS_TWO_OPTION[_.random(LIVENESS_STEPS_TWO_OPTION.length - 1)];
export const THREE_STEPS = [
    RANDOM_TWO_STEP,
    RANDOM_TWO_STEP_OPTION,
    { name: "straight", img: Svg.StraightIcon, text: "Giữ mặt thẳng 3s", layer: 'straightLayer' }];
// export const THREE_STEPS = [
// { name: "blink", img: smile, text: "Mỉm cười", layer:'straightLayer' },
// ];
export const RANDOM_STEP = randomStep(THREE_STEPS);

export const randomStepResponse = () => {
    const randomLeftRight = LIVENESS_STEPS_TWO[_.random(LIVENESS_STEPS_TWO.length - 1)];
    const randomBlinkSmile = LIVENESS_STEPS_TWO_OPTION[_.random(LIVENESS_STEPS_TWO_OPTION.length - 1)];
    const arrayStep = [
        randomLeftRight,
        randomBlinkSmile,
        // { name: "smile", img: smile, text: "Mỉm cười", layer:'straightLayer' },
        { name: "straight", img: Svg.StraightIcon, text: "Giữ mặt thẳng 3s", layer: 'straightLayer' }
    ];
    const result = randomStep(arrayStep);
    return result || [];
};

export const ERR_MESSAGE_NOT_ALLOW = "Vui lòng cho phép truy cập vào camera của bạn!";
export const ERR_MESSAGE_MANY_FACES = "Vui lòng đừng để nhiều hơn 1 khuôn mặt trong khung hình!";
export const ERR_MESSAGE_NO_FACE = "Vui lòng di chuyển mặt vào giữa khung hình";
export const ERR_MESSAGE_TIMEOUT = "Hết thời gian thao tác, vui lòng thử lại!";
export const ERR_MESSAGE_FACE_REAL = "Vui lòng không che hay để xa khuôn mặt của bạn khỏi khung hình!";
export const ERR_CAMERA_NOT_ALLOW = "Thiết bị của bạn không hỗ trợ truy cập camera!";
