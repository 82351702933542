import * as identifyConstant from '../constants/identify';

/** post CMND */
export const fetchPostCMND = (params = {}) => ({
    type: identifyConstant.FETCH_POST_CMND,
    payload: {
        params,
    }
});

export const fetchPostCMNDSuccess = (data) => ({
    type: identifyConstant.FETCH_POST_CMND_SUCCESS,
    payload: {
        data,
    },
});

export const fetchPostCMNDFailed = (error) => ({
    type: identifyConstant.FETCH_POST_CMND_FAILED,
    payload: {
        error,
    },
});

/** reset initial */
export const fetchResetInitial = () => ({
    type: identifyConstant.RESET_INITIAL,
});