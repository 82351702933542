import { makeStyles, withStyles, styled } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import { colors } from "constants/colors";

export const useStyles = makeStyles((theme) => ({
    cameraContainer: {
        display: "flex",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%"
    },

    cameraDiv: {
        display: "flex",
        flexDirection: 'column',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: colors?.backgroundB2
    },
    cameraPaper: {
        width: "100%",
        minHeight: "284px",
        marginBottom: 15
    },
    img: {
        width: "100%",
        height: "100%"
    },
    btnNext: {
        borderRadius: "6px",
        color: "white",
        fontWeight: "bold",
        backgroundColor: colors?.primaryP2,
        "&:hover": {
            backgroundColor: "#A2C1FA",
        },
        marginTop: 10,
        marginBottom: 10,
        padding: '14px 54px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 10,
        },
    },
    containerImgNew: {
        width: '100%', height: '100%',
        resize: 'cover',
        display: 'flex',
        justifyContent: 'center',
    },
    textInstructionNew: {
        textAlign: 'center',
        fontFamily: "Roboto",
        padding: '22px 20px 30px 20px',
        fontSize: 18,
        color: colors?.textT5,
        [theme.breakpoints.down('xs')]: {
            padding: '12px 20px 0px 20px',
            fontSize: 16,

        }
    },
    imgNew: {
        height: "271px",
        resize: 'cover',
        [theme.breakpoints.down('sm')]: {
            height: "200px",
        },
        [theme.breakpoints.down('xs')]: {
            height: "180px",
        }
    },
    btnNextInstructor: {
        borderRadius: "6px",
        color: "white",
        fontWeight: 700,
        fontSize: 18,
        backgroundColor: colors?.primaryP2,
        "&:hover": {
            backgroundColor: "#A2C1FA",
        },
        marginTop: 25,
        marginBottom: 10,
        padding: '10px 44px',
        [theme.breakpoints.down('sm')]: {
            marginBottom: 10,
            padding: '5px 44px',
            fontSize: 16,
        },
        [theme.breakpoints.down('sm')]: {
            marginBottom: 10,
            padding: '5px 44px',
            fontSize: 16,
        },
    },

    textInstruction: {
        fontFamily: "Roboto",
        textAlign: 'center',
        paddingTop: 10,
        color: colors?.textT5
    },
    headerInstruction: {
        fontFamily: "Roboto",
        textAlign: 'center',
        fontWeight: 'bold',
    },
    textIdentify: {
        fontFamily: "Roboto",
        fontWeight: "bold",
        padding: 15,
        borderBottom: '0.5px solid #DADBDD'
    },
    paperShow: {
        height: "100%",
        backgroundColor: "#E7E7E7",
        marginTop: 20,
        marginRight: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            height: "100%",
            marginRight: 5,
            marginBottom: 5,
        },
    },
    divShow: {
        flex: 3,
        height: "100%",
        borderBottom: "1px solid #CACACA",
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    divShowLast: {
        flex: 3,
        height: "100%",
        width: "100%",
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    textShow: {
        fontFamily: "Roboto",
        textAlign: 'center',
        paddingTop: 10,
        paddingBottom: 0,
        color: '#3B3B3B',
        fontWeight: 'bold'
    },
    imgShow: {
        width: '100%',
        resizeMode: 'cover',
        height: '100%',
        margin: '15px 0px 20px 0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            marginBottom: 15
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            margin: 0,
            marginBottom: 15
        },
    },
    boxBorder: {
        position: "absolute",
        top: 0,
        left: 0,
        width: "100%",
        height: "100%",
    },
    boxBorderWrapper: {
        position: "relative",
        width: "225px",
        height: "170px",
        display: "table",
        margin: "auto"
    },
    edge1: {
        position: "absolute",
        top: "15px",
        left: "15px",
    },
    edge2: {
        position: "absolute",
        top: "15px",
        right: "15px",
    },
    edge3: {
        position: "absolute",
        bottom: "15px",
        right: "15px",
    },
    edge4: {
        position: "absolute",
        bottom: "15px",
        left: "15px",
    },
    containerConstructor: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20,
        // margin: theme.spacing(7),
        // marginLeft:200,
        // marginRight:200,
        // [theme.breakpoints.down('sm')]: {
        //     marginLeft:20,
        //     marginRight:20,
        // }
    },
    containerImg: {
        display: 'flex',
        justifyContent: 'center',
        // marginBottom: theme.spacing(4),
    },
    containerImgMobile: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
        // marginBottom: theme.spacing(4),
    },
    detailInstructor: {
        display: 'flex',
        justifyContent: 'center',
        // margin: theme.spacing(7),
    },
    btnCamera: {
        borderRadius: "6px",
        color: "white",
        fontWeight: "bold",
        backgroundColor: colors?.primaryP2,
        "&:hover": {
            backgroundColor: "#A2C1FA",
        },
        marginTop: 70,
        padding: '6px 54px',
    },
    imgDivInstructorNew: {
        marginLeft: 27,
        marginRight: 27,
        display: 'flex', flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginTop: '20px',
            // marginBottom: '20px',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
            marginRight: 0,
            marginTop: '20px',
            width: '45%'
            // marginBottom: '20px'
        }
    },
    divInstructorNew: {
        flex: 5,
    },
    divInstructor: {
        flex: 5,
    },
    divInstructorDown: {
        flex: 5,
        marginTop: 40,
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
    },
    paperInstructor: {
        flex: 2,
        width: "400px",
        marginBottom: 20,
    },
    gridImg1: {
        paddingRight: 30,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0
        },
        [theme.breakpoints.down('xs')]: {
            paddingRight: 10,
            paddingLeft: 10
        }
    },
    paperFlexInstructor: {
        display: 'flex',
        flexDirection: 'row',
        padding: 10
    },
    divImg: {
        maxWidth: '251px',
        maxHeight: '165px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    gridItemFirst: {
        marginLeft: '45px !important',
        marginRight: '45px !important',
        [theme.breakpoints.down('md')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        }
    },
    gridItemSecondNone: {
        // marginLeft:'150px !important', 
        [theme.breakpoints.down('md')]: {
            display: 'none',
            marginRight: '30px !important',
            marginLeft: '30px !important',
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        }
    },
    gridItemSecond: {
        // marginLeft:'150px !important', 
        [theme.breakpoints.down('md')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        }
    },
    divSecond: {
        marginTop: 20,
        marginBottom: 20,
        backgroundColor: '#E7E7E7',
        borderRadius: '10px',
    },
    webcam: {
        marginLeft: "auto",
        marginRight: "auto",
        transform: "scaleX(-1)",
        height: "100%",
        [theme.breakpoints.down('xs')]: {
            height: "150%",
        }
    },
    avatar: {
        width: "325px !important",
        maxWidth: "100%",
        height: "325px !important",
        maxHeight: "100%",
        [theme.breakpoints.down("sm")]: {
            transform: "scale(0.7)",
        },
    },
    svgProcess: {
        position: "absolute",
        transition: "all .5s ease",
        maxWidth: 372,
        maxHeight: 372,
        [theme.breakpoints.down("sm")]: {
            transform: "scale(0.7)",
        },
        // [theme.breakpoints.down("xs")]: {
        //     transform: "scale(0.7)",
        //     width: "155px !important",
        //     height: "155px !important",
        // },
    },
    paperEmpty: {
        height: '165px',
        backgroundColor: '#E7E7E7',
    },
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: "10px !important",
            backgroundColor: colors?.backgroundB2
        }
    },
    divCancelIcon: {
        position: 'absolute',
        right: 15,
        marginTop: -20,
        cursor: 'pointer'
    },
    cancelIcon: {
        width: 33,
        height: 33,
        backgroundColor: '#ffffff',
        borderRadius: 30,
        position: 'fixed'
    },
    dialogContent: {
        padding: 40
    },
    dialogPaper: {
        // backgroundColor: "#FFFFFF",
        backgroundColor: colors?.backgroundB2,
        paddingTop: 30,
        paddingBottom: 30,
        paddingRight: 150,
        paddingLeft: 150,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 100,
            paddingLeft: 100,
        }
    },
    dialogGrid: {
        alignItems: "center",
        textAlign: 'center',
    },
    textAction: {
        textAlign: 'center',
        color: colors?.textT5
    },
    btnRedo: {
        borderRadius: "6px",
        color: "white",
        fontWeight: "bold",
        backgroundColor: colors?.primaryP2,
        "&:hover": {
            backgroundColor: "#A2C1FA",
        },
        marginTop: 20,
        marginBottom: 20,
        padding: '15px 54px',
        [theme.breakpoints.down('sm')]: {
            padding: '5px 24px',
        }
    },
    textError: {
        fontFamily: "Roboto",
        color: "#706F6F",
        marginTop: 10,
        marginBottom: 10,
    },
    headerError: {
        color: colors.textT5,
        fontFamily: "Roboto",
        fontWeight: "bold",
    },
    paperFirst: {
        // border: '1px solid #DADBDD',
        height: "94%",
        marginTop: 20,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            height: "98%",
        }
    },
    containerSecond: {
        display: "flex",
        justifyContent: "center"
    },
    liveness: {
        backgroundColor: colors?.backgroundB2,
        paddingLeft: 150,
        paddingRight: 150,
        width: '100%',
        height: '420px',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    textTitle: {
        textAlign: 'center',
        color: colors?.textT10
    },
    boxAvatar: {
        position: 'relative',
        left: 0,
    },
    divLiveThird: {
        flex: 2,
        marginTop: 0,
        marginBottom: 0,
        [theme.breakpoints.down('sm')]: {
            marginTop: "420px",
            marginBottom: "14px",
        },
    },
    divLiveFirst: {
        flex: 1,
        paddingTop: 15,
        paddingRight: 10,
        paddingLeft: 10
    },
    divLiveSecond: {
        flex: 8,
        display: "flex",
        justifyContent: "center",
        alignItems: 'center',
        position: "relative",
    },
    containerLive: {
        display: "flex",
        justifyContent: 'center',
    },
    liveInstructor: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center"
    },
    textProgress: {
        color: colors?.primaryP2,
        fontWeight: "bold",
        fontSize: "25px",
        textAlign: "center",
        paddingBottom: 20
    },
    boxProgress: {
        width: '100%',
        height: '165px',
        paddingLeft: 20,
        paddingRight: 20
    },
    imgDivInstructor: {
        width: '416px',
        height: '201px',
        [theme.breakpoints.down('sm')]: {
            width: '189px',
            height: '177px',
        },
    },
}));

export const BootstrapButton = withStyles({
    root: {
        textTransform: "none",
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
})(Button);

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
