/* eslint-disable react/prop-types */
import React from 'react';
import { Grid, Typography, Paper, Box, LinearProgress } from '@material-ui/core';
import { useStyles, BootstrapButton } from './styles';
import { styled } from '@material-ui/styles';
import { colors } from 'constants/colors';

const ColRightLayoutComponent = (props) => {
    const classes = useStyles();
    const {
        imageFront, imageBack, imageSelfie,
        verifyFront, verifyBack, handleClickImage,
        isLiveness, valueProgress
    } = props;
    return (
        <div className={classes.divSecond}>
            <Grid id="top-row" container className={classes.containerSecond}>
                <Grid item sm={12}>
                    <div className={classes.divShow}>
                        <Typography className={classes.textShow}>Ảnh Mặt Trước CMND/CCCD</Typography>
                        {imageFront && verifyFront ?
                            <div className={classes.divImgRight}
                                onClick={() => handleClickImage(imageFront)}
                            >
                                <img alt="front" src={imageFront} className={classes.imgShow} />
                            </div> : <Paper elevation={0} className={classes.paperEmpty}> </Paper>}
                    </div>
                </Grid>
            </Grid>
            <Grid id="bottom-row" container className={classes.containerSecond}>
                <Grid item sm={12}>
                    <div className={classes.divShow}>
                        <Typography className={classes.textShow}>Ảnh Mặt Sau CMND/CCCD</Typography>
                        {imageBack && verifyBack ?
                            <div className={classes.divImgRight} onClick={() => handleClickImage(imageBack)} >
                                <img alt="back" src={imageBack} className={classes.imgShow} />
                            </div> : <Paper elevation={0} className={classes.paperEmpty}> </Paper>}
                    </div>
                </Grid>
            </Grid>
            <Grid id="bottom-row" container className={classes.containerSecond}>
                <Grid item sm={12}>
                    <div className={classes.divShowLast}>
                        <Typography className={classes.textShow}>Chụp Ảnh Khuôn Mặt</Typography>
                        {imageSelfie && valueProgress >= 99 ?
                            <div className={classes.divImgRight} onClick={() => handleClickImage(imageSelfie)}>
                                <img alt="front" src={imageSelfie} className={classes.imgShow} />
                            </div> :
                            !isLiveness ?
                                <Paper elevation={0} className={classes.paperEmpty}> </Paper>
                                :
                                <Box
                                    sx={{ width: '100%', height: '165px', paddingLeft: 20, paddingRight: 20, paddingTop: 35, }}>
                                    <LinearProgressWithLabel value={valueProgress} />
                                </Box>
                        }
                    </div>
                </Grid>
            </Grid>
        </div>
    );
};

export default ColRightLayoutComponent;

const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: 15,
    borderRadius: 30,
    [`& .MuiLinearProgress-colorPrimary`]: {
        backgroundColor: "#CACACA",
        borderRadius: "30px !important",
        height: "15px !important",
    },
    [`& .MuiLinearProgress-barColorPrimary`]: {
        backgroundColor: colors?.primaryP2,
        height: "15px !important",
    },
}));

function LinearProgressWithLabel (props) {
    const { value } = props;
    const classes = useStyles();
    return (
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
            <Box sx={{ width: '100%' }} >
                <Typography className={classes.textProgress}
                    variant="body2">{`${Math.round(
                        value
                    )}%`}</Typography>
                <BorderLinearProgress variant="determinate" {...props} />
            </Box>
        </Box>
    );
}