/* eslint-disable react/prop-types */
import React from 'react';
import { useStyles, BootstrapButton } from './styles';
import {Grid, Typography} from '@material-ui/core';

function ItemNotice(props) {
    const classes = useStyles();
    const { image, txtNotice, name } = props;
    return (
        <div className={classes.imgDivInstructorNew}>
            <img alt={name} src={image} className={classes.imgNew} />
            <Typography className={classes.textInstructionNew}>{txtNotice}</Typography>
        </div>
    );
}

export default ItemNotice;
