/* eslint-disable react/prop-types */
import React from 'react';
import './styles.css';
import { Typography } from '@material-ui/core';
import useStyles from './styles';

function LoadingComponent(props) {
    const classes = useStyles();
    const { titleLoading } = props;
    return (
        <div className={classes?.divContainer}>
            <div className="lds-roller"><div></div><div></div><div>
            </div><div></div><div></div><div></div><div></div><div></div></div>
            <Typography className={classes?.txtLoading}>{titleLoading}</Typography>
        </div>
    );
}

export default LoadingComponent;
