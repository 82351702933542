/* eslint-disable react/prop-types */
import React from 'react';
import Result from './result';
import FaceCompare from './faceCompare';

const ConfirmInfoComponent= (props)=>{
    const {checkResult}= props;
    return (
        // checkResult ? <Result {...props} /> : <FaceCompare {...props} />
        <Result {...props} />
    );
};

export default ConfirmInfoComponent;