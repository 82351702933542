/* eslint-disable react/prop-types */
import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import StepConnector from '@material-ui/core/StepConnector';
import AdjustOutlinedIcon from '@material-ui/icons/AdjustOutlined';
import CheckCircleIcon from '@material-ui/icons/CheckCircle';
import CancelIcon from '@material-ui/icons/Cancel';
import clsx from 'clsx';
import { colors } from 'constants/colors';

export const useStyles = makeStyles((theme) => ({
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em'
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)'
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
            outline: '1px solid slategrey',
        }
    },
    container: {
        width: '100%',
        height: "100%",
        display: "flex",
        position: 'relative',
        flexDirection: 'column',
        background: colors?.background
    },
    button: {
        marginRight: theme.spacing(1),
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    label: {
        fontFamily: "Roboto",
        textAlign: "center",
        "& .MuiStepIcon-root": {
            color: "#DADBDD"
        },
        "& .MuiStepIcon-active": {
            color: "#FFFFFF"
        },
        "& .MuiStepIcon-text": {
            fill: "#2B2A2A",
            fontWeight: "bolder"
        },
        "& .MuiStepLabel-label": {
            color: "#FFFFFF",
            fontWeight: "bolder",
            fontSize: 18
        },
        [theme.breakpoints.down('sm')]: {
            "& .MuiStepLabel-label": {
                color: "#FFFFFF",
                fontWeight: 700,
                fontSize: 16
            },
            "& .MuiStepIcon-text": {
                fill: "#2B2A2A",
                fontWeight: 500
            },
        },
        [theme.breakpoints.down('xs')]: {
            "& .MuiStepLabel-label": {
                color: "#FFFFFF",
                fontWeight: 700,
                fontSize: 16
            },
            "& .MuiStepIcon-text": {
                fill: "#2B2A2A",
                fontWeight: 500
            },
        },
    },
    inActiveLabel: {
        "& .MuiStepIcon-root": {
            color: colors?.textT5,
        },
        "& .MuiStepLabel-label": {
            fill: colors?.textT5,
            fontWeight: "400"
        },
    },
    step: {
        width: '80%',
        maxWidth: 1000,
        backgroundColor: colors?.primaryP2,
        // paddingLeft:150,
        // paddingRight:150,
        paddingTop: 30,
        [theme.breakpoints.down('sm')]: {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    stepperDiv: {
        backgroundColor: colors?.primaryP2,
        marginTop: 0,
        display: 'flex', alignItems: 'center',
        justifyContent: 'center',
        // [theme.breakpoints.down('lg')]: {
        //     marginTop: 50
        // },
        // [theme.breakpoints.down('sm')]: {
        //     marginTop: 50,
        // },
    },
}));

export const QontoConnector = withStyles({
    alternativeLabel: {
        top: 10,
        left: 'calc(-50% + 16px)',
        right: 'calc(50% + 16px)',
    },
    active: {
        '& $line': {
            borderColor: '#eaeaf0',
        },
    },
    completed: {
        '& $line': {
            borderColor: '#eaeaf0',
        },
    },
    line: {
        borderColor: '#eaeaf0',
        borderTopWidth: 3,
        borderRadius: 1,
    },
})(StepConnector);

const useStepIcon = makeStyles({
    root: {
        color: '#AAAAAA',
    },
    active: {
        color: '#0066B3',
    },
    completed: {
        color: '#0066B3',
    },
    error: {
        color: '#D73133',
    }
});

export const StepIcon = (props) => {
    const classes = useStepIcon();
    const { active, completed, error } = props;
    return (
        <div
            className={clsx(classes.root, {
                [classes.active]: active,
                [classes.completed]: completed,
                [classes.error]: error,
            })}
        >
            {completed ? <CheckCircleIcon className={classes.completed} /> : (
                ((error && active) ? <CancelIcon className={classes.error} />
                    : <AdjustOutlinedIcon className={active ? classes.active : classes.root} />))}
        </div>
    );
};
