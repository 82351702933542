import { Paper, Typography } from '@material-ui/core';
import React from 'react';
import { BootstrapButton, useStyles } from './styles';
import internetImg from 'assets/images/internet.png';

const NoCamera = () => {
    const classes = useStyles();
    return (
        <div>
            <Paper elevation={0} className={classes.paperNotCamera}>
                <div className={classes.imgDiv}>
                    <img alt="internet" src={internetImg} />
                </div>
                <div style={{ flex: 7, paddingTop: 10 }}>
                    <Typography className={classes?.textNotice}>
                        Máy tính không có chức năng chụp ảnh vui lòng chép link sau đây
                        và truy cập trên trình duyệt điện thoại của bạn để chụp ảnh
                    </Typography>
                </div>
            </Paper>
            <BootstrapButton className={classes.btnLink} variant="outlined"
                onClick={(e) => { navigator.clipboard.writeText(window.location.href); }}>
                <Typography className={classes?.textLink}>{window.location.href}</Typography>
                <Typography className={classes.copyText}>Copy</Typography>
            </BootstrapButton>
        </div>
    );
};

export default NoCamera;
