import React from "react";
import { Route, Switch, BrowserRouter as Router, } from "react-router-dom";
import StepTestContainer from './Steps';
import FooterContainer from "./Footer";
import HeaderContainer from "./Header";


const RouterContainer= (props)=>{
    return (
        <div style={{display: 'flex',flexDirection: 'column',  position: 'absolute',top: 0, right: 0, bottom: 0, left: 0}}>
            <div style={{flex:0.5}}>
                <HeaderContainer {...props} />
            </div>
            <div style={{flex:8}}>
                <Router  >
                    <Switch>
                        <Route path="/" component={StepTestContainer} exact />
                    </Switch>
                </Router>
            </div>
            <div style={{flex:0.5}}>
                <FooterContainer {...props}  />
            </div>
        </div>
    );
    
};

export default RouterContainer;