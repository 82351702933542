/* eslint-disable no-undef */
require('dotenv').config();

export const API_END_POINT_OCR = process.env.REACT_APP_API_END_POINT_OCR;
export const API_END_POINT_FACE = process.env.REACT_APP_API_END_POINT_FACE;
/** start smartORC */
export const API_CMND = `${API_END_POINT_OCR}/api/v1/appstore/b94rP26Wqy/recognition`;
/** end smartORC */
/** start smartKYC */
export const API_FACE_COMPARISON = `${API_END_POINT_FACE}/api/v1/appstore/k4Xav7krbx/recognition`;
/** end smartKYC */