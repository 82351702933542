import React from 'react';
import {AppBar, Toolbar, Typography} from '@material-ui/core';
import logoImg from 'assets/images/logo.png';

const FooterComponent= (props)=>{
    return (
        <div style={{backgroundColor: "white", borderTop: "1px solid #DADBDD"}}>
            <Toolbar>
                <div style={{display:"flex", flexDirection:"column"}}>
                    <div style={{flex:5}}>
                        <img src={logoImg} alt="logo" style={{height:10}} />
                    </div>
                    <div style={{flex:5}}>
                        <Typography style={{color: '#616161', fontSize:"14px", textAlign:"start"}}>
                            © 2021 Công ty Cổ Phần GMO-Z.com RUNSYSTEM</Typography>
                    </div>
                </div>
            </Toolbar>
        </div>
    );
};

export default FooterComponent;