/* eslint-disable react/prop-types */
import React, { useState, useRef, useEffect } from 'react';
import IdentifyComponent from 'components/Identify/index.jsx';
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as identifyActions from 'actions/identify';
import { withRouter } from "react-router";
import {isEmpty, getCroppedImg, resizeImg} from 'commons/shared';
import {ERR_MESSAGE_NOT_ALLOW} from 'constants/liveness';

const moment = require('moment-timezone');
const IdentifyContainer= (props)=>{
    const { history, handleImgCMND1, handleImgCMND2, handleNext, infoCMND,
        imgCMND1, imgCMND2, postCMNDCreators,
        handleErrorRetry, handleReset, errorCMND} = props;

    const [image1, setImage1]= useState({ preview: "", raw: ""});
    const [image2, setImage2]= useState({ preview: "", raw: ""});
    const [instructor, setInstructor]= useState(true);
    const [camera1, setCamera1]= useState(false);
    const [camera2, setCamera2]= useState(false);
    const [verify1, setVerify1]= useState(false);
    const [verify2, setVerify2]= useState(false);
    const [errorNotFound, setErrorNotFound]= useState("");
    const [errorCallAPI, setErrorCallAPI]= useState("");
    const [borderImg1, setBorderImg1]= useState({
        width: '',
        height:'',
        x: '',
        y: ''
    });
    const [borderImg2, setBorderImg2]= useState({
        width: '',
        height:'',
        x: '',
        y: ''
    });
    const video1= useRef(null);
    const { fetchPostCMND, } = postCMNDCreators;

    const postCMND = () => {
        const image1= imgCMND1?.raw;
        const image2= imgCMND2?.raw;
        const formData= new FormData();
        formData.append("image1", image1);
        formData.append("image2", image2);
        formData.append("encode", 1);
        if(fetchPostCMND){
            fetchPostCMND(formData);
        }
    };

    useEffect(()=> {
        navigator.mediaDevices.getUserMedia({video: true}) .then((stream)=> {
            stream.getTracks().forEach(function(track) {
                track.stop();
            });
        }, (err)=> {
            setErrorNotFound(ERR_MESSAGE_NOT_ALLOW);
        });
    }, []);

    useEffect(()=>{
        const date_time= localStorage.getItem("tokenTime");
        var isBefore = moment(date_time).isBefore(moment());
        if(!date_time && imgCMND1.preview && imgCMND2.preview && verify1 && verify2){
            postCMND();
        } else if (date_time && isBefore && imgCMND1.preview && imgCMND2.preview && verify1 && verify2){
            postCMND();
        } else if(date_time && !isBefore){
            handleErrorRetry("Đã hết số lượt thực hiện lại cho phép. Quý khách vui lòng quay lại sau 10 phút");
        } else {
            handleErrorRetry("");
            localStorage.removeItem("tokenTime");
        }
    }, [imgCMND1, imgCMND2, verify1, verify2]);

    useEffect(async ()=>{
        if(!isEmpty(infoCMND)){
            handleNext();
        }
    }, [infoCMND]);

    useEffect(()=> {
        if(errorCMND){
            setErrorCallAPI("Có lỗi hệ thống xảy ra, quý khách vui lòng thực hiện lại quá trình eKYC");
        }
    }, [errorCMND]);

    /** start webcam */
    const webcamRef1 = useRef(null);
    const canvasRef1 = useRef(null);
    const webcamRef2 = useRef(null);
    const canvasRef2 = useRef(null);
    const capture1 = React.useCallback(
        async () => {
            const imageSrc = webcamRef1.current.getScreenshot();
            return imageSrc;
        },
        [webcamRef1]
    );

    const capture2 = React.useCallback(
        async () => {
            const imageSrc = webcamRef2.current.getScreenshot();
            return imageSrc;
        },
        [webcamRef2]
    );
    /** end webcam */
    
    /** start capture image */
    const handleCameraClick1= async ()=>{
        setCamera1(true);
        setImage1({ preview: "", raw: "" });
        if(webcamRef1.current !== null){
            const img1= await capture1();
            const blob = await fetch(img1).then((res) => res.blob());
            var file = new File([blob], "image1.jpg");
            
            // console.log({newImg});
            // newImg.onload = ({target:img}) => {
            //     console.log({img},newImg?.width);
            //     const { naturalWidth: width, naturalHeight: height } = newImg;
            //     console.log({width,height});
            //     // resolve({ width, height });
            // };
            setImage1({
                preview: URL.createObjectURL(blob),
                raw: file
            });
            handleImgCMND1({
                preview: URL.createObjectURL(blob),
                raw: file
            });
        } else if(video1.current !==null){
            let stream = await navigator.mediaDevices.getUserMedia({ video: true, audio: false });
            video1.srcObject = stream;
        }
    };
    const handleCameraClick2= async ()=>{
        setCamera2(true);
        setImage2({ preview: "", raw: "" });
        if(webcamRef2.current !== null){
            const img2= await capture2();
            const blob = await fetch(img2).then((res) => res.blob());
            var file = new File([blob], "image2.jpg");
            setImage2({
                preview: URL.createObjectURL(blob),
                raw: file
            });
            handleImgCMND2({
                preview: URL.createObjectURL(blob),
                raw: file
            });
        }
    };
    /** end capture image */

    const onUserMediaErrorChange =() => {
        // setError(ERR_MESSAGE_NOT_ALLOW);
    };

    const handleNextCamera= ()=>{
        const date_time= localStorage.getItem("tokenTime");
        var isBefore = moment(date_time).isBefore(moment());
        if(date_time && isBefore){
            handleErrorRetry("");
            localStorage.removeItem("tokenTime");
            setInstructor(false);
        } else if(date_time && !isBefore) {
            handleErrorRetry("Đã hết số lượt thực hiện lại cho phép. Vui lòng thử lại sau 10 phút");
        } else {
            handleErrorRetry("");
            localStorage.removeItem("tokenTime");
            window.scrollTo(0,0);
            setInstructor(false);
        }
    };

    const handleClickRedo1= ()=>{
        setVerify1(false);
        setImage1({ preview: "", raw: "" });
        setCamera1(true);
    };

    const handleClickVerify1= async ()=>{
        if(borderImg1.width){
            const newImg = new Image();
            newImg.src = URL.createObjectURL(imgCMND1?.raw);
            newImg.onload = () => {
                const { naturalWidth: width, naturalHeight: height } = newImg;
                // resolve({ width, height });
            };

            const crop= { 
                unit: 'px', // default, can be 'px' or '%'
                x:borderImg1.x,
                y:borderImg1.y,
                width: borderImg1.width,
                height: borderImg1.height};
            const croppedImg = await getCroppedImg(imgCMND1.raw, crop, "myFile");
            setImage1({
                preview: URL.createObjectURL(croppedImg),
                raw: imgCMND1.raw
            });
            handleImgCMND1({
                preview: URL.createObjectURL(croppedImg),
                raw: imgCMND1.raw
            });
        }
        setVerify1(true);
    };

    const handleClickRedo2= ()=>{
        setVerify2(false);
        setImage2({ preview: "", raw: "" });
        setCamera2(true);
    };

    const handleClickVerify2= async ()=>{
        if(borderImg2.width){
            const crop= { 
                unit: 'px', // default, can be 'px' or '%'
                x:borderImg2.x,
                y: borderImg2.y,
                width: borderImg2.width,
                height: borderImg2.height};
            const croppedImg = await getCroppedImg(imgCMND2.raw, crop, "myFile");
            setImage2({
                preview: URL.createObjectURL(croppedImg),
                raw: imgCMND2.raw
            });
            handleImgCMND2({
                preview: URL.createObjectURL(croppedImg),
                raw: imgCMND2.raw
            });
        }
        setVerify2(true);
    };

    const handleFinishCamera= ()=>{
        setInstructor(true);
        handleReset();
    };


    return <IdentifyComponent history={history} errorNotFound={errorNotFound}
        webcamRef1= {webcamRef1} canvasRef1={canvasRef1} capture1={capture1}
        webcamRef2= {webcamRef2} canvasRef2={canvasRef2} capture2={capture2}
        image1= {image1} image2={image2} camera1={camera1} camera2={camera2}
        handleCameraClick1={handleCameraClick1} handleCameraClick2={handleCameraClick2}
        onUserMediaErrorChange={onUserMediaErrorChange} handleFinishCamera={handleFinishCamera}
        instructor={instructor} handleNextCamera={handleNextCamera} handleClickRedo1={handleClickRedo1}
        handleClickVerify1={handleClickVerify1} verify1={verify1} handleClickRedo2={handleClickRedo2}
        handleClickVerify2={handleClickVerify2} verify2={verify2} errorCallAPI={errorCallAPI}
        setBorderImg1={setBorderImg1} video1={video1} setBorderImg2={setBorderImg2} {...props} />;
};

const mapStateToProps = (state) => ({
    infoCMND: state.identify.infoCMND,
    errorCMND: state.identify.errorCMND,
    isPostingCMND: state.identify.isPostingCMND,
});
const mapDispatchToProps = (dispatch) => ({
    postCMNDCreators: bindActionCreators(identifyActions, dispatch),
});
const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withRouter, withConnect)(IdentifyContainer);