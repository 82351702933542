/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { Grid, Paper, Typography, Dialog } from '@material-ui/core';
import { useStyles, BootstrapButton } from './styles';
import instructor1 from 'assets/images/instructor1.png';
import instructor2 from 'assets/images/instructor2.png';
// import mini1 from 'assets/images/mini1.png';
import mini2 from 'assets/images/mini2.png';
import mini3 from 'assets/images/mini3.png';
import mini4 from 'assets/images/mini4.png';
import warningImg from 'assets/images/warning.png';
import './style.css';
import { assets } from '../../assets';
import ItemNotice from './itemNotice';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { createTheme } from '@material-ui/core';
import { Svg } from 'assets/svg';

const Instruction = (props) => {
    const classes = useStyles();
    const { handleNextCamera, errorRetry, } = props;
    const [openError, setOpenError] = React.useState(false);
    const baseTheme = createTheme();

    const isMobile = useMediaQuery(baseTheme.breakpoints.down('xs'), {
        defaultMatches: true
    });

    useEffect(() => {
        if (errorRetry) {
            setOpenError(true);
        }
    }, [errorRetry]);

    const handleCloseError = () => {
        setOpenError(false);
    };
    return (
        <Grid container direction='row' className={classes.containerConstructor}>
            <Grid item sm={12}>
                <Typography className={classes.headerInstruction}>Lưu ý khi chụp giấy tờ tùy thân</Typography>
            </Grid>
            <Grid item sm={12}>
                <Grid container className={classes.containerImg}>
                    {/* <Grid item sm={6} xs={6}>  */}
                    <div className={classes.imgDivInstructor} style={{ marginLeft: 5 }}>
                        <img alt="instructor1" src={Svg.StraightImage}
                            className={classes.img} />
                        {/* <Svg.StraightImage /> */}
                        <Typography className={classes.textInstruction}>Đặt giấy tờ trên mặt phẳng và chụp thẳng góc </Typography>
                    </div>
                    {/* </Grid> */}
                    {/* <Grid item sm={6} xs={6}> */}
                    <div className={classes.imgDivInstructor} style={{ marginRight: 5 }}>
                        {/* <img alt="instructor2" src={isMobile ? assets?.imageFalse : instructor2} className={classes.img} /> */}
                        <img alt="instructor2" src={Svg.WrongImage} className={classes.img} />
                        <Typography className={classes.textInstruction}>Không cầm trên tay</Typography>
                    </div>
                    {/* </Grid> */}
                </Grid>
                <Grid container className={classes.containerImg}>
                    <Grid item>
                        <ItemNotice
                            alt='mini1'
                            image={Svg?.AntiGlareIdentityCard}
                            title='Đảm bảo ảnh rõ nét, không bị mờ lóa'
                        />
                    </Grid>
                    <Grid item>
                        <ItemNotice
                            alt='mini3'
                            image={Svg?.CutIdentityCard}
                            title='Không dùng giấy tờ bị cắt góc, quăn mép, bị nhòe'
                        />
                    </Grid>
                </Grid>
                <Grid container className={classes.containerImg}>
                    <Grid item>
                        <ItemNotice
                            alt='mini2'
                            image={Svg?.OriginalIdentityCard}
                            title='Sử dụng giấy tờ còn hạn sử dụng'
                        />
                    </Grid>
                    <Grid item>
                        <ItemNotice
                            alt='mini4'
                            image={Svg?.OriginalIdentityCard}
                            title='Không sử dụng bản scan, photocopy'
                        />
                    </Grid>
                </Grid>
            </Grid>
            <Grid item>
                <BootstrapButton className={classes.btnNext} onClick={handleNextCamera}>Tiếp Tục</BootstrapButton>
            </Grid>
            {/* dialog error */}
            {
                errorRetry && <Dialog className={classes.dialog}
                    open={openError}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <div className={classes.divCancelIcon} onClick={handleCloseError}>
                        <img alt="front" src={assets?.iconClose} className={classes.cancelIcon} />
                    </div>
                    <Paper className={classes.dialogPaper}>
                        <Grid container direction="column" className={classes.dialogGrid}>
                            <div className={classes.imageDiv}>
                                <img className={classes.imageError} alt="cancel"
                                    src={warningImg} />
                            </div>
                            <Typography className={classes.headerError}>Thông báo</Typography>
                            <Typography className={classes.textError}>
                                {errorRetry || ''}</Typography>
                            <BootstrapButton className={classes.btnRedo} onClick={handleCloseError}>
                                Kết Thúc
                            </BootstrapButton>
                        </Grid>
                    </Paper>
                </Dialog>
            }
        </Grid >
    );
};

export default Instruction;