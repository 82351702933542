/* eslint-disable react/prop-types */
import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import {useStyles, QontoConnector} from './styles';
import './style.css';
import clsx from 'clsx';
  
const StepComponent =(props)=> {
    const classes = useStyles();
    const {activeStep, steps,
        getStepContent, hidden, errorStep}= props;
    return (
        <div className={classes.container}>
            <div className={classes.stepperDiv}>
                <Stepper activeStep={activeStep} alternativeLabel 
                    className={classes.step} style={{display: hidden ? "none" :""}}>
                    {steps.map((label, index) => {
                        return (
                            <Step key={label} alternativeLabel connector={<QontoConnector />}>
                                <StepLabel className={clsx(classes.label,{
                                    [classes?.inActiveLabel]:!(activeStep === index || activeStep > index  || activeStep === steps.length)
                                })} error={errorStep}
                                active= {(activeStep=== index || activeStep > index  || activeStep === steps.length) ? true : false}
                                >{ label}</StepLabel>
                            </Step>
                        );
                    })}
                </Stepper>
            </div>
            <div >
                {getStepContent(activeStep)}
            </div>
        </div>
     
    );
};
export default StepComponent;