/* eslint-disable react/prop-types */
import React from 'react';
import Webcam from 'react-webcam';

const Capture= (props)=>{
    const {webcamRef, canvasRef, onUserMediaErrorChange,
        videoConstraints, facingMode}= props;
    return (
        <div style={{ width:"100%",height:"100%"}}>
            <Webcam
                id="fs_video"
                ref={webcamRef}
                audio={false}
                screenshotFormat="image/jpeg"
                screenshotQuality={1}
                onUserMediaError={onUserMediaErrorChange}
                videoConstraints={{
                    ...videoConstraints,
                    facingMode
                }}
                forceScreenshotSourceSize
                quality={1}
                imageSmoothing
                style={{
                    // position: "relative",
                    // marginLeft: "auto",
                    // marginRight: "auto",
                    // left: 0,
                    // right: 0,
                    // textAlign: "center",
                    width:"100%",
                    height:"100%",
                    // objectFit: "fill",
                }}

            />
            <canvas
                id="fs_canvas"
                width='100%'
                height='100%'
                ref={canvasRef}
                style={{
                    // width:'100%',height:'100%',
                    position: "absolute",
                    // position: "relative",
                    // marginLeft: "auto",
                    // marginRight: "auto",
                    left: 0,
                    right: 0,
                    top:0,
                    zIndex: 2,
                    // textAlign: "center",
                }}
            />
        </div>
    );
};

export default Capture;