import { makeStyles, withStyles, styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import { colors } from "constants/colors";

export const useStyles = makeStyles((theme) => ({
    cameraContainer: {
        display: "flex",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%"
    },

    cameraDiv: {
        display: "flex",
        flexDirection: 'column',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: colors?.backgroundB2
    },
    cameraPaper: {
        width: "100%",
        minHeight: "284px",
        marginBottom: 15
    },
    img: {
        width: "100%",
        height: "100%"
    },
    btnNext: {
        borderRadius: "6px",
        color: "white",
        fontWeight: "bold",
        backgroundColor: colors?.primaryP2,
        "&:hover": {
            backgroundColor: colors?.primaryP3,
        },
        marginTop: 0,
        padding: '14px 54px'
    },
    textInstruction: {
        fontFamily: "Roboto",
        textAlign: 'center',
    },
    headerInstruction: {
        fontFamily: "Roboto",
        textAlign: 'center',
        fontWeight: 'bold',
    },
    textIdentify: {
        fontFamily: "Roboto",
        fontWeight: "bold",
        padding: 15,
        borderBottom: `0.5px solid #DADBDD`,
        color: colors?.textT10
    },
    paperShow: {
        height: "92%",
        backgroundColor: "#E7E7E7",
        marginTop: 20,
        marginRight: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            height: "92%",
            marginRight: 5,
            marginBottom: 5,
        },
    },
    divShow: {
        flex: 3,
        height: "100%",
        borderBottom: "1px solid #CACACA",
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    divShowLast: {
        flex: 3,
        height: "100%",
        width: "100%",
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    textShow: {
        fontFamily: "Roboto",
        textAlign: 'center',
        paddingTop: 10,
        paddingBottom: 0,
        color: '#3B3B3B',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            paddingBottom: 10,
            paddingTop: 15,
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 15,
            paddingBottom: 10,
        },
    },
    imgShow: {
        width: '100%',
        height: '100%',
        resize: 'cover',
        padding: '15px 0px 20px 0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            // margin:0,
            marginBottom: 15
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            // margin:0,
            marginBottom: 15
        },
    },
    containerConstructor: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(7),
    },
    containerImg: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(4),
    },
    detailInstructor: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(7),
    },
    btnInCorrect: {
        borderRadius: "6px",
        color: "#757474",
        fontWeight: "bold",
        backgroundColor: "#FFFFFF",
        marginRight: 20,
        padding: '10px 34px',
        border: '1px solid #DADBDD',
        [theme.breakpoints.down('sm')]: {
            padding: '5px 14px',
        }
    },
    btnCorrect: {
        borderRadius: "6px",
        color: "white",
        fontWeight: "bold",
        backgroundColor: colors?.primaryP2,
        "&:hover": {
            backgroundColor: colors?.primaryP3,
        },
        padding: '10px 44px',
        [theme.breakpoints.down('sm')]: {
            padding: '5px 24px',
        }
    },
    textField: {
        paddingBottom: 20,
        "& .MuiFilledInput-input": {
            color: "#1665D8",
            fontSize: '18px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '16px',
            }
        },
        "& .MuiFilledInput-root": {
            backgroundColor: "#FFFFFF",
            borderRadius: 6
        },
    },
    divImg: {
        maxWidth: '251px',
        maxHeight: '165px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center'
    },
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: "10px !important"
        }
    },
    dialogContent: {
        padding: 40
    },
    divCancelIcon: {
        position: 'absolute',
        right: 15,
        marginTop: -20,
        cursor: 'pointer'
    },
    cancelIcon: {
        width: 33,
        height: 33,
        borderRadius: '50%',
        position: 'fixed'
    },
    dialogPaper: {
        width: '100%',
        backgroundColor: "#FFFFFF",
        padding: 30,
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        }
    },
    imageError: {
        width: '122px', height: '120px',
        [theme.breakpoints.down('sm')]: {
            width: '92px', height: '90px',
        },
        [theme.breakpoints.down('xs')]: {
            width: '82px', height: '80px',
        }
    },
    dialogGrid: {
        width: '90%',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        textAlign: 'center'
    },
    headerError: {
        paddingTop: 30,
        color: "#121212",
        fontFamily: "Roboto",
        fontWeight: "bold",
        [theme.breakpoints.down('xs')]: {
            paddingTop: 15,
        }
    },
    textError: {
        fontFamily: "Roboto",
        color: "#706F6F",
        marginTop: 10,
        marginBottom: 10,
    },
    btnRedo: {
        borderRadius: "6px",
        color: "white",
        fontWeight: "bold",
        backgroundColor: colors?.primaryP2,
        "&:hover": {
            backgroundColor: "#A2C1FA",
        },
        marginTop: 20,
        marginBottom: 20,
        padding: '15px 54px',
        [theme.breakpoints.down('sm')]: {
            padding: '5px 24px',
        }
    },
    gridItemFirst: {
        marginLeft: '45px !important',
        marginRight: '45px !important',
        [theme.breakpoints.down('md')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        }
    },
    divInformation: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 40,
        [theme.breakpoints.down('sm')]: {
            paddingTop: 20,
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 20,
        }
    },
    gridItemSecondNone: {
        // marginLeft:'150px !important', 
        [theme.breakpoints.down('md')]: {
            display: 'none',
            marginRight: '30px !important',
            marginLeft: '30px !important',
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        }
    },
    gridItemSecond: {
        // marginLeft:'150px !important', 
        [theme.breakpoints.down('md')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        }
    },
    divSecond: {
        marginTop: 20,
        marginBottom: 20,
        backgroundColor: '#E7E7E7',
        borderRadius: '10px',
    },
    paperEmpty: {
        height: '165px',
        backgroundColor: 'red',
    },
    containerSecond: {
        display: "flex",
        justifyContent: "center"
    },
    paperFirst: {
        // border: '1px solid #DADBDD',
        height: "94%",
        marginTop: 20,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            height: "98%",
        }
    },
    containerCamera: {
        backgroundColor: colors?.background
    }
}));

export const BootstrapButton = withStyles({
    root: {
        textTransform: "none",
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
})(Button);

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
