import imageErrorIdCard from './images/svg/imageErrorIdCard.svg';
import imageErrorIdCardFirst from './images/svg/imageErrorIdCardFirst.svg';
import iconClose from './images/svg/iconClose.svg';
import notScanIdCard from './images/svg/notScanIdCard.svg';
import leftLayer from './images/svg/leftLayer.svg';
import rightLayer from './images/svg/rightLayer.svg';
import straightLayer from './images/svg/straightLayer.svg';

import process_0 from 'assets/images/svg/process_0.svg';
import process_33 from 'assets/images/svg/process_33.svg';
import process_66 from 'assets/images/svg/process_66.svg';
import process_99 from 'assets/images/svg/process99.svg';

// import imageFalse from './images/imageFalse.png';
// import imageMobile from './images/imageMobile.png';
import errorLight from './images/errorLight.png';
import trueFace from './images/trueFace.png';
import noKT from './images/noKT.png';
import falseNoGrass from './images/falseNoGrass.png';

export const assets = {
    imageErrorIdCard, imageErrorIdCardFirst, iconClose,
    errorLight,trueFace, noKT,falseNoGrass,
    notScanIdCard, leftLayer, rightLayer, straightLayer,
    process_0,process_33, process_66,process_99
}; 