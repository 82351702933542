import { makeStyles } from '@material-ui/styles';

export default makeStyles((theme)=>({
    divContainer:{
        position: 'absolute',
        width:'100vw',
        height:'100%',
        left:0, top:0,
        zIndex:10,
        backgroundColor:'rgba(69, 69, 69, 0.7)',
        display:'flex',flexDirection: 'column',
        alignItems: 'center', justifyContent: 'center',
        textAlign:'center'
    },
    txtLoading:{
        position:'absolute',
        top:'calc(50vh + 0px)',
        left:'calc(50vw - 50px)',
        fontSize:20, fontWeight:700,
        color:'#ffffff',
        [theme.breakpoints.down('sm')]: {
            fontSize:18,
            top:'calc(50vh + 0px)',
            left:'calc(50vw - 45px)',
        },
        [theme.breakpoints.down('xs')]: {
            fontSize:16,
            top:'calc(50vh + 100px)',
            left:'calc(50vw - 45px)',
        },
    },
}));