/* eslint-disable react/prop-types */
import React from "react";
import Instructor from './instructor';
import Liveness from './liveness';

const LivenessComponent = (props) => {
    const {
        instructor,
    } = props;

    return (
        instructor ? <Instructor {...props} /> : <Liveness {...props} />
    );
};

export default LivenessComponent;
