import slider1 from 'assets/images/slider1.png';
import slider2 from 'assets/images/slider2.png';
import slider3 from 'assets/images/slider3.png';

export const TEST_STEPS= ['Bước 1: Chụp ảnh CMND/CCCD', 'Bước 2: Xác thực thông tin', 'Bước 3: Kiểm tra người thật'];

export const TEST_STEPS_OPTION= ['Bước 1: Chụp hoặc tải ảnh CMND/CCCD', 'Bước 2: Xác thực thông tin'];

export const CHECK_STEPS= ['Xác thực CMND/CCCD & Chân dung', 'Chữ ký'];

export const DATA_SLIDER= [
    {
        id: 1,
        title: "slider1",
        subTitle: "Công nghệ OCR thông tin, nhận dạng chính xác từng điểm ảnh",
        src: slider1
    },
    {
        id: 2,
        title: "slider2",
        subTitle: "Liveness chính xác, nhanh chóng nhiều action random",
        src: slider2
    },
    {
        id: 3,
        title: "slider3",
        subTitle: "Facematching, Facedetection áp dụng các thuật toán chống giả mạo",
        src: slider3
    },
];

export const ERROR_IDENTIFY= [
    {
        code: 'I1',
        key: 'front_blur_check',
        value: '0',
        title: '',
        text: 'Ảnh mặt trước bị mờ',
    },
    {
        code: 'I2',
        key: 'back_blur_check',
        value: '0',
        title: '',
        text: 'Ảnh mặt sau bị mờ',
    },
    {
        code: 'I3',
        key: 'no_front_check',
        value: '0',
        title: 'Ảnh đầu vào không hợp lệ',
        text: 'Ảnh mặt trước không chứa CMND/CCCD',
    },
    {
        code: 'I4',
        key: 'no_back_check',
        value: '0',
        title: '',
        text: 'Ảnh mặt sau không chứa CMND/CCCD',
    },
    {
        code: 'I5',
        key: 'id_format_check',
        value: '0',
        title: '',
        text: 'Kết quả bóc tách của "Số CMND" không hợp lệ',
    },
    {
        code: 'I6',
        key: 'dob_format_check',
        value: '0',
        title: '',
        text: 'Kết quả bóc tách của "Ngày sinh" không hợp lệ',
    },
    {
        code: 'I7',
        key: 'doi_format_check',
        value: '0',
        title: '',
        text: 'Kết quả bóc tách của "Ngày cấp" không hợp lệ',
    },
    {
        code: 'I8',
        key: 'doe_format_check',
        value: '0',
        title: '',
        text: 'Kết quả bóc tách của "Ngày hết hạn" không hợp lệ',
    },
    {
        code: 'I9',
        key: 'capture_far_check',
        value: '0',
        title: '',
        text: 'Ảnh chụp giấy tờ từ quá xa khung hình',
    },
    {
        code: 'F1',
        key: 'corner_check',
        value: '0',
        title:'Ảnh đầu vào có dấu hiệu giả mạo',
        text: 'Ảnh đầu vào có dấu hiệu mất góc hoặc bị che khuất',
    },
    {
        code: 'F2',
        key: 'black_white_check',
        value: '0',
        title: '',
        text: 'Giấy tờ trên ảnh đầu vào có dấu hiệu là photocopy đen trắng',
    },
    {
        code: 'F3',
        key: 'through_screen_check',
        value: '0',
        title: '',
        text: 'Ảnh đầu vào được chụp qua màn hình máy tính/tivi',
    },
    {
        code: 'F4',
        key: 'front_back_check',
        value: '0',
        title:'Ảnh đầu vào không hợp lệ',
        text: 'Mặt trước/Mặt sau không cùng một loại CMND/CCCD',
    },
    {
        code: 'F5',
        key: 'expired_check',
        value: '0',
        title: '',
        text: 'CMND/CCCD đã hết hạn',
    },
    {
        code: 'L1',
        key: 'dob_of_future_check',
        value: '0',
        title: '',
        text: 'Ngày sinh muộn hơn ngày hiện tại',
    },
    {
        code: 'L3',
        key: 'doe_before_dob_check',
        value: '0',
        title: '',
        text: 'Ngày hết hạn sớm hơn ngày sinh',
    },
    {
        code: 'L8',
        key: 'id_sex_check',
        value: '0',
        title: '',
        text: 'Số CMND/CCCD không hợp lệ',
    },
    {
        code: 'L9',
        key: 'id_dob_check',
        value: '0',
        title: '',
        text: 'Số CMND/CCCD không hợp lệ',
    },
    {
        code: 'L11',
        key: 'doi_of_future_check',
        value: '0',
        title: '',
        text: 'Ngày cấp muộn hơn ngày hiện tại',
    },
    {
        code: 'L12',
        key: 'doi_dob_14_check',
        value: '0',
        title: '',
        text: 'Tuổi cấp thẻ nhỏ hơn 14 tuổi',
    },
    {
        code: 'L13',
        key: 'doe_doi_15_check',
        value: '0',
        title: '',
        text: 'Ngày hết hạn không hợp lệ',
    },
    {
        code: 'L14',
        key: 'doe_doi_25_check',
        value: '0',
        title: '',
        text: 'Ngày hết hạn không hợp lệ',
    },
    {
        code: 'L15',
        key: 'doe_doi_40_check',
        value: '0',
        title: '',
        text: 'Ngày hết hạn không hợp lệ',
    },
    {
        code: 'L16',
        key: 'doe_doi_60_check',
        value: '0',
        title: '',
        text: 'Ngày hết hạn không hợp lệ',
    },
];

export const numOtp= 4;

export const STATUS_CODE = {
    SUCCESS: 200,
    CREATED: 201,
    UPDATED: 202,
};
