import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import identifyReducer from './identify';
import faceReducer from './face';

const reducer = combineReducers({
    form: formReducer,
    identify: identifyReducer,
    face: faceReducer,
});
const rootReducer = (state, action) => {
    let stateTemp = state;
    if (action.type === 'RESET_INITIAL') {
        stateTemp = undefined;
    }
    return reducer(stateTemp, action);
};

export default rootReducer;