/* eslint-disable no-mixed-spaces-and-tabs */
/* eslint-disable react/prop-types */
import React from 'react';
import {
    Box, Dialog,
    DialogContent,
    Grid, Paper, Typography,
    createTheme
} from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { ThemeProvider } from '@material-ui/styles';
import cameraImg from 'assets/images/Camera.png';
import errorImg from 'assets/images/error.png';
import warningImg from 'assets/images/warning.png';
import { colors } from 'constants/colors';
import { useEffect, useState } from 'react';
import { assets } from '../../assets';
import ColRightLayout from '../ColRightLayout';
import LoadingComponent from '../LoadingComponent';
import NoCameraComponent from './NoCamera';
import './style.css';
import { BootstrapButton, useStyles } from './styles';
import Capture from "./webcam";

const FACING_MODE_ENVIRONMENT = "environment";

const facingMode = "environment";

const Instruction = (props) => {
    const classes = useStyles();
    const {
        webcamRef1,
        canvasRef1,
        webcamRef2,
        canvasRef2,
        camera1,
        camera2,
        handleCameraClick1,
        handleCameraClick2,
        image1,
        image2,
        imgLive,
        verify1,
        handleClickRedo1,
        handleClickVerify1,
        verify2,
        handleClickRedo2,
        handleClickVerify2,
        imgDialog, imgDialogOpen,
        handleClickImage, handlImgDialogClose,
        errorRetry, handleFinishCamera, error,
        errorNotFound, errorCallAPI, isPostingCMND,
        setBorderImg1, setBorderImg2,
    } = props;
    const [openError, setOpenError] = useState(false);
    const [showYellow, setShowYellow] = useState(false);
    useEffect(() => {
        if (errorRetry || error || errorCallAPI) {
            setOpenError(true);
        }
    }, [errorRetry, error, errorCallAPI]);

    useEffect(() => {
        if (camera1) {
            setTimeout(() => {
                setShowYellow({
                    ...showYellow,
                    camera1: true
                });
            }, 500);
        }
        if (camera2) {
            setTimeout(() => {
                setShowYellow({
                    ...showYellow,
                    camera2: true
                });
            }, 500);
        }
    }, [camera1, camera2]);

    const handleCloseError = () => {
        setOpenError(false);
    };
    const baseTheme = createTheme();
    const isMobile = useMediaQuery(baseTheme.breakpoints.down('xs'), {
        defaultMatches: true
    });
    const boxFront = document.querySelector('#box-img1');
    const boxBorder = document.querySelector('#borderImage_front');

    const videoConstraints = {
        // width: { ideal: isMobile ?  3000 :3000 },
        // height: { ideal:isMobile? 3000: 3000 },
        facingMode: FACING_MODE_ENVIRONMENT,
    };

    useEffect(() => {
        if (boxFront) {
            const devicePixelRatio = window.devicePixelRatio;
            const cWidth = boxFront.offsetWidth;
            const cHeight = boxFront.offsetHeight;
            const bdWidth = boxBorder.offsetWidth;
            const bdHeight = boxBorder.offsetHeight;

            setBorderImg1({
                width: bdWidth,
                height: bdHeight,
                x: Math.round((cWidth - bdWidth) / 2),
                y: Math.round((cHeight - bdHeight) / 2),
            });
        }
    }, [boxFront]);

    const boxBack = document.querySelector('#box-img2');
    const boxBorderBack = document.querySelector('#borderImage_back');

    useEffect(() => {
        if (boxBack) {
            const devicePixelRatio = window.devicePixelRatio;
            const cWidth = boxBack.offsetWidth;
            const cHeight = boxBack.offsetHeight;
            const bdWidth = boxBorderBack.offsetWidth;
            const bdHeight = boxBorderBack.offsetHeight;

            setBorderImg2({
                width: bdWidth,
                height: bdHeight,
                x: Math.round((cWidth - bdWidth) / 2),
                y: Math.round((cHeight - bdHeight) / 2),
            });
        }
    }, [boxBack]);

    return (
        <ThemeProvider theme={baseTheme}>
            <Box>
                <Grid container direction='row' justifyContent='center'
                    style={{ backgroundColor: colors?.background }}>
                    <Grid item xs={12} sm={12} md={1} lg={2} className={classes.gridItemSecondNone} style={{ backgroundColor: 'unset' }}>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItemFirst}>
                        <Grid container direction='row' className={classes.containerConstructor}>
                            {!verify1 && <Grid item sm={12} xs={12}>
                                <Paper className={classes.cameraPaper}>
                                    <div className={classes.cameraDiv}>
                                        <div style={{ flex: 1, backgroundColor: colors?.background }}>
                                            <Typography className={classes.textIdentify}>Ảnh Mặt trước CMND/CCCD</Typography></div>
                                        {errorNotFound ?
                                            <NoCameraComponent />
                                            :
                                            image1.preview ?
                                                <div className={classes?.divPreviewImage}>
                                                    <div className={classes.divImg}>
                                                        {/* <img alt="front" src={image1.preview} className={classes.imgShow} /> */}
                                                        <div className={classes.boxBorderImg}
                                                            style={{
                                                                backgroundImage: `url(${image1.preview})`,
                                                                backgroundRepeat: 'no-repeat',
                                                                backgroundPosition: 'center',
                                                                backgroundSize: 'contain'
                                                            }}
                                                            id='box-img1'>
                                                            <div className={classes.boxBorderWrapperImg} id='borderImage_front'>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div >
                                                        <BootstrapButton className={classes.btnInCorrect} onClick={handleClickRedo1}>
                                                            Chụp lại
                                                        </BootstrapButton>
                                                        <BootstrapButton className={classes.btnCorrect} onClick={handleClickVerify1}>
                                                            Xác nhận
                                                        </BootstrapButton>
                                                    </div>
                                                </div> :
                                                camera1 ? <div style={{ maxWidth: "100%", maxHeight: "100%", position: "relative" }}>
                                                    <div className={classes.imgCapture}>
                                                        {isMobile ? <Capture
                                                            webcamRef={webcamRef1}
                                                            canvasRef={canvasRef1}
                                                            videoConstraints={videoConstraints}
                                                            facingMode={facingMode}
                                                            {...props}
                                                        /> : <Capture
                                                            webcamRef={webcamRef1}
                                                            canvasRef={canvasRef1}
                                                            {...props}
                                                        />}
                                                        {showYellow.camera1 && <div className={classes.boxBorder}>
                                                            <div className={classes.boxBorderWrapper}>
                                                            </div>
                                                        </div>}
                                                    </div>

                                                    <div style={{
                                                        flex: 9, display: "flex", justifyContent: "center", paddingBottom: 20,
                                                    }}>
                                                        <BootstrapButton className={classes.btnCamera} onClick={handleCameraClick1}>
                                                            <img alt="camera1" src={cameraImg} className={classes.iconCamera} />
                                                            Chụp Ảnh
                                                        </BootstrapButton>
                                                    </div>
                                                </div> :
                                                    <div style={{ flex: 9, display: "flex", justifyContent: "center", }}>
                                                        <Paper style={{ color: "red" }}></Paper>
                                                        <BootstrapButton className={classes.btnOnlyCamera} onClick={handleCameraClick1}>
                                                            <img alt="camera1" src={cameraImg} className={classes.iconCamera} />
                                                            Chụp Ảnh
                                                        </BootstrapButton>
                                                    </div>
                                        }
                                    </div>
                                </Paper>
                            </Grid>}
                            {!verify2 && <Grid item sm={12} xs={12}>
                                <Paper className={classes.cameraPaper}>
                                    <div className={classes.cameraDiv}>
                                        <div style={{ flex: 1, backgroundColor: colors?.background }}>
                                            <Typography className={classes.textIdentify}>Ảnh Mặt Sau CMND/CCCD</Typography></div>
                                        {errorNotFound ?
                                            <NoCameraComponent /> :
                                            image2.preview ?
                                                <div className={classes?.divPreviewImage}>
                                                    <div className={classes.divImg}>
                                                        {/* <img alt="back" src={image2.preview} className={classes.imgShow} /> */}
                                                        <div
                                                            className={classes.boxBorderImg}
                                                            style={{
                                                                backgroundRepeat: 'no-repeat',
                                                                backgroundPosition: 'center',
                                                                backgroundImage: `url(${image2.preview})`,
                                                                backgroundSize: 'contain'
                                                            }}
                                                            id='box-img2'>
                                                            <div className={classes.boxBorderWrapperImg} id='borderImage_back'>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div >
                                                        <BootstrapButton className={classes.btnInCorrect} onClick={handleClickRedo2}>
                                                            Chụp lại
                                                        </BootstrapButton>
                                                        <BootstrapButton className={classes.btnCorrect} onClick={handleClickVerify2}>
                                                            Xác nhận
                                                        </BootstrapButton>
                                                    </div>
                                                </div> :
                                                camera2 ? <div style={{ maxWidth: "100%", maxHeight: "100%", position: "relative" }}>
                                                    <div className={classes.imgCapture}>
                                                        {isMobile ? <Capture
                                                            webcamRef={webcamRef2}
                                                            canvasRef={canvasRef2}
                                                            videoConstraints={videoConstraints}
                                                            facingMode={facingMode}
                                                            {...props}
                                                        /> : <Capture
                                                            webcamRef={webcamRef2}
                                                            canvasRef={canvasRef2}
                                                            {...props}
                                                        />}
                                                        {showYellow.camera2 && <div className={classes.boxBorder}>
                                                            <div className={classes.boxBorderWrapper}>
                                                            </div>
                                                        </div>}
                                                    </div>
                                                    <div style={{ flex: 9, display: "flex", justifyContent: "center", paddingBottom: 20 }}>
                                                        <BootstrapButton className={classes.btnCamera} onClick={handleCameraClick2}>
                                                            <img alt="camera1" src={cameraImg} className={classes.iconCamera} />
                                                            Chụp Ảnh
                                                        </BootstrapButton>
                                                    </div>
                                                </div> :
                                                    <div style={{ flex: 9, display: "flex", justifyContent: "center" }}>
                                                        <BootstrapButton className={classes.btnOnlyCamera} onClick={handleCameraClick2}>
                                                            <img alt="camera1" src={cameraImg} className={classes.iconCamera} />
                                                            Chụp Ảnh
                                                        </BootstrapButton>
                                                    </div>


                                        }
                                    </div>
                                </Paper>
                            </Grid>}
                            {isPostingCMND &&
                                <LoadingComponent titleLoading='Đang xử lý...' />
                            }
                        </Grid>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={2} className={classes.gridItemSecond}>
                        <ColRightLayout
                            imageFront={image1 && image1?.preview}
                            imageBack={image2 && image2?.preview}
                            imageSelfie={imgLive && imgLive?.preview}
                            verifyFront={verify1}
                            verifyBack={verify2}
                            handleClickImage={handleClickImage}
                        />
                    </Grid>
                </Grid>
                {/* dialog img */}
                <Dialog className={classes.dialog}
                    open={imgDialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <div className={classes.divCancelIcon} onClick={handlImgDialogClose}>
                        <img alt="front" src={assets?.iconClose} className={classes.cancelIcon} />
                    </div>
                    <DialogContent className={classes.dialogContent}>
                        <img alt="front" src={imgDialog} className={classes.imgDialogShow} />
                    </DialogContent>
                </Dialog>
                {/* dialog error */}
                {openError && <Dialog className={classes.dialog}
                    open={openError}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <div className={classes.divCancelIcon} onClick={handleCloseError}>
                        <img alt="front" src={assets?.iconClose} className={classes.cancelIcon} />
                    </div>
                    <Paper className={classes.dialogPaper}>
                        <Grid container direction="column" className={classes.dialogGrid}>
                            <div className={classes.imageDiv}>
                                <img className={classes.imageError} alt="cancel"
                                    src={errorCallAPI ? errorImg : warningImg} />
                            </div>
                            <Typography className={classes.headerError}>
                                {errorCallAPI ? 'Lỗi hệ thống' : 'Thông báo'}</Typography>
                            <Typography className={classes.textError}>
                                {errorRetry || error || errorCallAPI || ''}</Typography>
                            <BootstrapButton className={classes.btnRedo} onClick={handleFinishCamera}>
                                Kết Thúc
                            </BootstrapButton>
                        </Grid>
                    </Paper>
                </Dialog>}
            </Box>
        </ThemeProvider >
    );
};

export default Instruction;