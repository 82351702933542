/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import {TEST_STEPS} from 'constants/commons';
import StepComponent from 'components/Steps/index.jsx';
import IdentifyContainer from 'containers/Identify';
import LivenessContainer from 'containers/Liveness';
import ResultCompareContainer from 'containers/ResultCompare';
import ConfirmInfoContainer from 'containers/ConfirmInfo';
import * as facemesh from '@tensorflow-models/face-landmarks-detection';

const moment = require('moment-timezone');

function getSteps() {
    return TEST_STEPS;
}

const StepContainer= (props)=>{
    const {history}= props;
    const [activeStep, setActiveStep] = useState(0);
    const [skipped, setSkipped] = useState(new Set());
    const [imgCMND1, setImgCMND1]= useState({ preview: "", raw: ""});
    const [imgCMND2, setImgCMND2]= useState({ preview: "", raw: ""});
    const [imgLive, setImgLive]= useState({ preview: "", raw: ""});
    const [imgSignature, setImgSignature]= useState({ preview: "", raw: ""});
    const [hidden, setHidden]= useState(false);
    const [errorStep, setErrorStep]= useState(false);
    const [numRetryDiscorrect, setNumRetryDiscorrect]= useState(0);
    const [numErrorConfirm, setNumErrorConfirm]= useState(0);
    const [numErrorSystem, setNumErrorSystem]= useState(0);
    const [numErrorLiveness, setNumErrorLiveness]= useState(0);
    const [errorRetry, setErrorRetry]= useState("");
    const [DetectNet,setDetectNet] = useState(null);

    const steps = getSteps();

    useEffect(async()=>{
        await runFacemesh();
    },[]);

    async function runFacemesh () {
        const optionsFaceMesh = {
            inputResolution: { width: "100%", height: "100%" },
            // scale: 0.8,
            // maxFaces: 1
        };
        const result = await facemesh.load(facemesh.SupportedPackages.mediapipeFacemesh,
            optionsFaceMesh);
        if(result){
            setDetectNet(result);
        }
    }

    useEffect(()=> {
        if(numRetryDiscorrect===5 || numErrorConfirm=== 5 
            || numErrorSystem===5 || numErrorLiveness===5){
            const now= moment();
            var newDateObj = moment(now).add(10, 'm').toDate();
            localStorage.setItem("tokenTime", newDateObj);
        }
    }, [numRetryDiscorrect, numErrorConfirm, numErrorSystem, numErrorLiveness]);

    const handleErrorRetry= (msg)=>{
        setErrorRetry(msg);
    };
  
    const isStepSkipped = (step) => {
        return skipped.has(step);
    };
  
    const handleNext = () => {
        let newSkipped = skipped;
        if (isStepSkipped(activeStep)) {
            newSkipped = new Set(newSkipped.values());
            newSkipped.delete(activeStep);
        }
  
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        setSkipped(newSkipped);
    };
  
    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };
  
    const handleReset = () => {
        setActiveStep(0);
        setImgCMND1({ preview: "", raw: ""});
        setImgCMND2({ preview: "", raw: ""});
        setImgLive({ preview: "", raw: ""});
    };

    const handleImgCMND1= (img)=>{
        if(img){
            setImgCMND1(img);
        }
    };

    const handleImgCMND2= (img)=>{
        if(img){
            setImgCMND2(img);
        }
    };

    const handleImgLive= (img)=>{
        if(img){
            setImgLive(img);
        }
    };

    const handleImgSignature= (img)=>{
        if(img){
            setImgSignature(img);
        }
    };

    const handleHidden= ()=>{
        setHidden(true);
    };

    const handleRemoveHidden= ()=>{
        setHidden(false);
    };

    const handleErrorStep= ()=>{
        setErrorStep(true);
    };

    const resetErrorStep=()=>{
        setErrorStep(false);
    };

    const [imgDialog, setImgDialog]= useState("");
    const [imgDialogOpen, setImgDialogOpen]= useState(false);
    const handleClickImage= (image)=>{
        setImgDialog(image);
        setImgDialogOpen(true);
    };

    const handlImgDialogClose= ()=>{
        setImgDialogOpen(false);
    };

    const handleRetryIdentify= ()=>{
        setNumRetryDiscorrect((prevNum)=> prevNum+ 1);
    };

    const handleErrorConfirm= ()=>{
        setNumErrorConfirm((prevNum)=> prevNum+ 1);
    };

    const handleErrorSystem= ()=>{
        setNumErrorSystem((prevNum)=> prevNum+ 1);
    };

    const handleErrorLiveness= ()=>{
        setNumErrorLiveness((prevNum)=> prevNum+ 1);
    };

    const getStepContent= (step) => {
        switch (step) {
        case 0:
            return <IdentifyContainer hidden={hidden} handleHidden={handleHidden}
                activeStep= {activeStep} steps= {steps} handleRemoveHidden={handleRemoveHidden}
                isStepSkipped= {isStepSkipped} handleImgCMND1={handleImgCMND1} 
                handleImgCMND2={handleImgCMND2} imgCMND1={imgCMND1} imgCMND2={imgCMND2}
                imgLive={imgLive} handleImgLive={handleImgLive} imgSignature={imgSignature}
                handleImgSignature={handleImgSignature} errorStep={errorStep} handleErrorStep={handleErrorStep}
                handleReset={handleReset} getStepContent={getStepContent} resetErrorStep={resetErrorStep}
                handleBack= {handleBack} handleNext= {handleNext} imgDialog={imgDialog}
                imgDialogOpen={imgDialogOpen} handleClickImage={handleClickImage} handlImgDialogClose={handlImgDialogClose}
                numRetryDiscorrect={numRetryDiscorrect} handleRetryIdentify={handleRetryIdentify} errorRetry={errorRetry}
                handleErrorRetry={handleErrorRetry} handleErrorConfirm={handleErrorConfirm}
                handleErrorSystem={handleErrorSystem} handleErrorLiveness={handleErrorLiveness}
                {...props} />;
        case 1:
            return <ConfirmInfoContainer hidden={hidden} handleHidden={handleHidden}
                activeStep= {activeStep} steps= {steps} imgCMND1={imgCMND1} imgCMND2={imgCMND2}
                isStepSkipped= {isStepSkipped} imgLive={imgLive} handleImgLive={handleImgLive}
                handleReset={handleReset} getStepContent={getStepContent} handleRemoveHidden={handleRemoveHidden}
                imgSignature={imgSignature} handleImgSignature={handleImgSignature}
                handleBack= {handleBack} handleNext= {handleNext} errorStep={errorStep} 
                handleErrorStep={handleErrorStep} resetErrorStep={resetErrorStep}
                imgDialog={imgDialog} imgDialogOpen={imgDialogOpen} 
                handleClickImage={handleClickImage} handlImgDialogClose={handlImgDialogClose}
                numRetryDiscorrect={numRetryDiscorrect} handleRetryIdentify={handleRetryIdentify} errorRetry={errorRetry}
                handleErrorRetry={handleErrorRetry} handleErrorConfirm={handleErrorConfirm}
                handleErrorSystem={handleErrorSystem} handleErrorLiveness={handleErrorLiveness}
                {...props} />;
        case 2:
            return <LivenessContainer hidden={hidden} handleHidden={handleHidden}
                activeStep= {activeStep} steps= {steps} handleRemoveHidden={handleRemoveHidden}
                isStepSkipped= {isStepSkipped} handleImgCMND1={handleImgCMND1} 
                handleImgCMND2={handleImgCMND2} imgCMND1={imgCMND1} imgCMND2={imgCMND2}
                imgLive={imgLive} handleImgLive={handleImgLive} errorStep={errorStep} handleErrorStep={handleErrorStep}
                imgSignature={imgSignature} handleImgSignature={handleImgSignature}
                handleReset={handleReset} getStepContent={getStepContent} handleBack= {handleBack}
                handleNext= {handleNext} resetErrorStep={resetErrorStep} 
                imgDialog={imgDialog} imgDialogOpen={imgDialogOpen} errorRetry={errorRetry}
                handleClickImage={handleClickImage} handlImgDialogClose={handlImgDialogClose}
                numRetryDiscorrect={numRetryDiscorrect} handleRetryIdentify={handleRetryIdentify}
                handleErrorRetry={handleErrorRetry} handleErrorConfirm={handleErrorConfirm} 
                DetectNet={DetectNet}
                handleErrorSystem={handleErrorSystem} handleErrorLiveness={handleErrorLiveness}
                {...props}/>;
                
        default:
            return null;
        }
       
    };

    useEffect(()=>{
        if(activeStep=== steps.length){
            handleReset();
            history.replace("/");
            window.localStorage.removeItem("isUpload");
            window.localStorage.removeItem("isSignature");
        }
    }, [activeStep]);

    return <StepComponent 
        hidden={hidden} 
        handleHidden={handleHidden}
        activeStep= {activeStep} 
        steps= {steps} 
        errorStep={errorStep} 
        handleErrorStep={handleErrorStep}
        isStepSkipped= {isStepSkipped} 
        handleImgCMND1={handleImgCMND1} 
        handleImgCMND2={handleImgCMND2} 
        handleImgLive={handleImgLive}
        imgCMND1={imgCMND1} 
        imgCMND2={imgCMND2} 
        imgLive={imgLive}
        handleReset={handleReset} 
        getStepContent={getStepContent} 
        handleBack= {handleBack}
        handleNext= {handleNext} 
        resetErrorStep={resetErrorStep}
        {...props} />;
};

export default StepContainer;