export const colors = {
    primaryP1: '#172150',
    primaryP2: "#0749AC",
    primaryP3: "#0067B6",
    primaryP4: "#108CCF",
    primaryP5: "rgba(7, 73, 172, 0.6)",

    secondaryS1: "#0DA79F",

    background: "#1E1E1E",
    backgroundB1: "#000512",
    backgroundB2: "#1E212C",
    backgroundB25: "#1B1C1F",
    backgroundGrey: "#A19E97",

    semantic: "#FE5050",

    textT5: "#A0AEC0",
    textT10: "#FAFAFA"
};