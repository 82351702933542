/* eslint-disable no-undef */
/* eslint-disable no-mixed-spaces-and-tabs */
import axios from 'axios';
const empty = require('is-empty');
require('dotenv').config();

export const axiosBodyToAPI = async (method, uri, body, requestId) => {
    try {
        let headerConfig;
        if (requestId) {
            headerConfig = {
                headers: {
                    "Authorization": `Bearer ${process.env.REACT_APP_AUTHORIZATION_API_KEY}`,
                    "session_id": process.env.REACT_APP_SESSION_ID,
                    "request_id": requestId,
                },
            };
        } else {
            headerConfig = {
                headers: {
                    "Authorization": `Bearer ${process.env.REACT_APP_AUTHORIZATION_API_KEY}`,
                    "session_id": process.env.REACT_APP_SESSION_ID,
                },
            };
        }
        let result;
        if (method === 'POST') {
            result = await axios.post(uri, body, headerConfig);
        } else if (method === 'PUT') {
            result = await axios.put(uri, body, headerConfig);
        } else if (method === 'DELETE') {
            const config = {
                ...headerConfig,
                data: body,
            };
            result = await axios.delete(uri, config);
        } else {
            result = await axios.post(uri, body, headerConfig);
        }
        return result;
    } catch (error) {
        return error;
    }
};

export const isEmpty = (value) => empty(value);

export const randomStep = (array) => {
    var tmp, current, top = array.length;
    if (top) while (--top) {
        current = Math.floor(Math.random() * (top + 1));
        tmp = array[current];
        array[current] = array[top];
        array[top] = tmp;
    }
    return array;
};

export const getRandom = (arr, n) => {
    var result = new Array(n),
        len = arr.length,
        taken = new Array(len);
    if (n > len)
        throw new RangeError("getRandom: more elements taken than available");
    while (n--) {
        var x = Math.floor(Math.random() * len);
        result[n] = arr[x in taken ? taken[x] : x];
        taken[x] = --len in taken ? taken[len] : len;
    }
    return result;
};

export const isPhoneNumber = (phone) => {
    const regex = /(03|05|07|08|09|01[2|6|8|9])+([0-9]{8})\b/;
    return regex.test(phone);
};

export const getCroppedImg = (imageFile, pixelCrop, fileName) => {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
    var image = new Image();
    image.src = URL.createObjectURL(imageFile);
    var promise = new Promise((resolve, reject) => {
        image.onload = function () {
            ctx.save();
            ctx.drawImage(
                image,
                -pixelCrop.x,
                -pixelCrop.y,
                pixelCrop.width + (pixelCrop.x * 2 + 10),
                pixelCrop.height + (pixelCrop.y * 2 + 10),
            );
            resolve();
        };
    }).then(function () {
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/jpeg');
        });
    });
    return promise;
};

export const getCroppedImgFinal = (imageFile, pixelCrop, fileName) => {
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
    var image = new Image();
    image.src = URL.createObjectURL(imageFile);

    var promise = new Promise((resolve, reject) => {
        image.onload = function () {
            ctx.save();
            ctx.drawImage(
                image,
                pixelCrop.x,
                pixelCrop.y,
                pixelCrop.width,
                pixelCrop.height,
                0,
                0,
                pixelCrop.width,
                pixelCrop.height
            );
            resolve();
        };
    }).then(function () {
        return new Promise((resolve, reject) => {
            canvas.toBlob((blob) => {
                blob.name = fileName;
                resolve(blob);
            }, 'image/jpeg');
        });
    });
    return promise;
};