/* eslint-disable react/prop-types */
import React from 'react';
import {Grid, Typography} from '@material-ui/core';
import { useStyles, BootstrapButton } from './styles';
import liveInstructor1 from 'assets/images/liveInstructor1.png';
import liveInstructor2 from 'assets/images/liveInstructor2.png';
import liveInstructor3 from 'assets/images/liveInstructor3.png';
import liveInstructor4 from 'assets/images/liveInstructor4.png';
import './style.css';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import ItemNoticeComponent from './itemNotice';
import { assets } from '../../assets';

const Instruction= (props)=>{
    const classes = useStyles();
    const {handleNextLiveness}= props;
    const baseTheme = createTheme();
    const isMobile = useMediaQuery(baseTheme.breakpoints.down('xs'), {
        defaultMatches: true
    });
    return (
        <ThemeProvider theme={baseTheme}>
            <Grid container direction='row' className={classes.containerConstructor}>
                <Grid item sm={12}>
                    <Typography className={classes.headerInstruction}>Hướng dẫn chụp ảnh khuôn mặt</Typography>
                </Grid>
                {isMobile ?
                    <Grid item sm={12}>
                        <Grid container direction='column' className={classes?.containerImgNew}>
                            <Grid container className={classes.containerImgMobile}>
                                <ItemNoticeComponent
                                    txtNotice='Không đeo kính'
                                    name='liveInstructor1'
                                    image={ isMobile ? assets?.falseNoGrass :liveInstructor1}
                                />
                                <ItemNoticeComponent
                                    txtNotice='Không đeo khẩu trang'
                                    name='liveInstructor3'
                                    image={isMobile ? assets?.noKT : liveInstructor3}
                                />
                            </Grid>
                            <Grid container className={classes.containerImgMobile}>
                                <ItemNoticeComponent
                                    txtNotice='Luôn giữ khuôn mặt trong khung hình'
                                    name='liveInstructor2'
                                    image={isMobile ? assets?.trueFace : liveInstructor2}
                                />
                                <ItemNoticeComponent
                                    txtNotice='Không quay ngược sáng'
                                    name='liveInstructor4'
                                    image={isMobile ? assets?.errorLight : liveInstructor4}
                                />
                            </Grid>
                        </Grid>
                    </Grid>
                    : <Grid item sm={12}>
                        <Grid container className={classes.containerImg}>
                            <Grid item className={classes.gridImg1}>
                                <div style={{display:"flex", flexDirection: 'column',top: 0, right: 0, bottom: 0, left: 0}}>
                                    <div className={classes.divInstructor}>
                                        <img alt="liveInstructor1" src={liveInstructor1} className={classes.img} />
                                        <Typography className={classes.textInstruction}>Không đeo kính</Typography>
                                    </div>
                                    <div className={classes.divInstructorDown}>
                                        <img alt="liveInstructor2" src={liveInstructor2} className={classes.img} />
                                        <Typography className={classes.textInstruction}>Luôn giữ khuôn mặt trong khung hình</Typography>
                                    </div>
                                </div> 
                            </Grid>
                            <Grid item>
                                <div style={{display:"flex", flexDirection: 'column',top: 0, right: 0, bottom: 0, left: 0}}>
                                    <div className={classes.divInstructor}>
                                        <img alt="liveInstructor3" src={liveInstructor3} className={classes.img} />
                                        <Typography className={classes.textInstruction}>Không đeo khẩu trang</Typography>
                                    </div>
                                    <div className={classes.divInstructorDown}>
                                        <img alt="liveInstructor4" src={liveInstructor4} className={classes.img} />
                                        <Typography className={classes.textInstruction}>Không quay ngược sáng</Typography>
                                    </div>
                                </div>
                            </Grid>
                        </Grid>
                    </Grid>}  
                <Grid item>
                    <BootstrapButton className={classes.btnNextInstructor} onClick={handleNextLiveness}>Tiếp Tục</BootstrapButton>
                </Grid>
            </Grid>
        </ThemeProvider>
    );
};

export default Instruction;