/* eslint-disable react/prop-types */
import React, { useState, useEffect } from 'react';
import { useStyles, BootstrapButton } from './styles';
import {
    Grid, Paper, Typography, Box, Dialog,
    DialogContent, DialogContentText
} from '@material-ui/core';
import ok from 'assets/images/ok.png';
import notOk from 'assets/images/notOk.png';
import warningImg from 'assets/images/warning.png';
import { assets } from '../../assets';
import ColRightLayout from '../ColRightLayout';

const ConfirmInfoComponent = (props) => {
    const { imgCMND1, imgCMND2, imgLive,
        infoFaceComparison,
        imgDialog, imgDialogOpen, imgCrop,
        handleClickImage, handlImgDialogClose,
        imgCropLive, handleClickCheckResult, error,
        handleClickFinish, } = props;
    const classes = useStyles();
    const [openError, setOpenError] = useState(false);
    useEffect(() => {
        if (error) {
            setOpenError(true);
        }
    }, [error]);
    const handleCloseError = () => {
        setOpenError(false);
    };
    return (
        <Box>
            <Grid container direction='row' justifyContent='center'>
                <Grid item xs={12} sm={12} md={1} lg={2} className={classes.gridItemSecond}>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItemFirst}>
                    <Paper className={classes.paperFirst}>
                        <div className={classes.cameraDiv}>
                            <div style={{ flex: 1 }}>
                                <Typography className={classes.textTitle}>Kết quả so sánh khuôn mặt</Typography>
                            </div>
                            <div style={{ flex: 4 }}>
                                <Grid container className={classes?.divRowImage}>
                                    <div className={classes.img1}>
                                        <div className={classes.divText1}>
                                            <Typography className={classes?.textTitleKey}>Mặt trên giấy tờ</Typography>
                                        </div>
                                        <div className={classes.divPaper1}>
                                            <img alt="front" src={imgCrop ? imgCrop : ""
                                            } className={classes.imgLive} />
                                        </div>
                                    </div>
                                    <div className={classes.img2}>
                                        <div className={classes.divText2}>
                                            <Typography className={classes?.textTitleKey}>Mặt trên chân dung</Typography>
                                        </div>
                                        <div className={classes.divPaper2}>
                                            <img alt="live" src={imgCropLive ? imgCropLive : ""
                                            } className={classes.imgLive} />
                                        </div>
                                    </div>
                                </Grid>
                            </div>
                            <div style={{ flex: 3 }}>
                                <div className={classes.divImgError}>
                                    <div className={infoFaceComparison?.face_compare === 1 ? classes.flexImgSuccess : classes.flexImgError}>
                                        <div style={{ flex: 5, paddingBottom: 20 }}>
                                            <img alt="left" src={infoFaceComparison?.face_compare === 1 ? ok : notOk} style={{ height: "60px" }} />
                                        </div>
                                        <div style={{ flex: 5 }}>
                                            <Typography className={classes.textErrorCompare}>
                                                {infoFaceComparison?.face_compare === 1 ? 'Cùng một người' : 'Không cùng một người'}</Typography>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div style={{ flex: 2 }}>
                                <div style={{ display: "flex", justifyContent: 'center' }}>
                                    <BootstrapButton className={classes.btnFinishLive} onClick={handleClickCheckResult}>
                                        Kết Quả OCR
                                    </BootstrapButton>
                                </div>
                            </div>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={2} className={classes.gridItemSecond}>
                    <ColRightLayout
                        imageFront={imgCMND1 && imgCMND1?.preview}
                        imageBack={imgCMND2 && imgCMND2?.preview}
                        imageSelfie={imgLive && imgLive?.preview}
                        verifyFront={true}
                        verifyBack={true}
                        handleClickImage={handleClickImage}
                        valueProgress={99}
                    />
                </Grid>
            </Grid>
            {/* dialog img */}
            <Dialog className={classes.dialog}
                open={imgDialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div className={classes.divCancelIcon} onClick={handlImgDialogClose}>
                    <img alt="front" src={assets?.iconClose} className={classes.cancelIcon} />
                </div>
                <DialogContent className={classes.dialogContent}>
                    <DialogContentText id="alert-dialog-description">
                        <img alt="front" src={imgDialog} className={classes.imgShow} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>
            {/* dialog error */}
            {openError && <Dialog className={classes.dialog}
                open={openError}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div className={classes.divCancelIcon} onClick={handleCloseError}>
                    <img alt="front" src={assets?.iconClose} className={classes.cancelIcon} />
                </div>
                <Paper className={classes.dialogPaper}>
                    <Grid container direction="column" className={classes.dialogGrid}>
                        <div className={classes.imageDiv}>
                            <img className={classes.imageError} alt="cancel"
                                src={warningImg} />
                        </div>
                        <Typography className={classes.headerError}>
                            {'Thông báo'}</Typography>
                        <Typography className={classes.textError}>
                            {error || ''}</Typography>
                        <BootstrapButton className={classes.btnRedo} onClick={handleClickFinish}>
                            Kết Thúc
                        </BootstrapButton>
                    </Grid>
                </Paper>
            </Dialog>}
        </Box>
    );
};

export default ConfirmInfoComponent;