/* eslint-disable react/prop-types */
import React, { useEffect, useState } from 'react';
import ResultCompareComponent from 'components/ResultCompare/index';
import { withRouter } from "react-router";
import {bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import {isEmpty, getCroppedImgFinal} from 'commons/shared';
import * as identifyActions from "actions/identify";
import {STATUS_CODE} from '../../constants/identify';

const ResultCompareContainer= (props)=>{
    const {infoCMND, infoFaceComparison, handleBackCheck,
        handleReset, postCMNDCreators, imgLive, handleErrorSystem}= props;
    const { fetchResetInitial } = postCMNDCreators;
    const [imgCrop, setImgCrop]= useState("");
    const [imgCropLive, setImgCropLive]= useState("");
    const [error, setError]= useState("");
    const handleClickBack= ()=>{
        handleBackCheck();
    };
    const handleClickFinish = () => {
        handleReset();
        if (fetchResetInitial) {
            fetchResetInitial();
        }
    };
    const [checkResult, setCheckResult]= useState(false);
    const handleClickCheckResult= ()=>{
        setCheckResult(true);
    };

    const handleClickBackFaceCompare= ()=>{
        setCheckResult(false);
    };

    useEffect(()=>{
        if(infoFaceComparison){
            if(infoFaceComparison?.result_code !==200){
                setError(STATUS_CODE[infoFaceComparison?.result_code]);
                handleErrorSystem();
            } 
        }
    }, [infoFaceComparison]);

    useEffect(async()=> {
        if(!isEmpty(infoFaceComparison?.bbox_faces1)){
            const imgCMND= infoCMND?.data?.image_roi;
            const newImgCMND= `data:image/jpeg;base64,${imgCMND}`;
            const blob = await fetch(newImgCMND).then((res) => res.blob());
            const file = new File([blob], "imgCMND.jpg");
            const width=  infoFaceComparison?.bbox_faces1[0][2]- infoFaceComparison?.bbox_faces1[0][0];
            const height=  infoFaceComparison?.bbox_faces1[0][3]- infoFaceComparison?.bbox_faces1[0][1];
            const crop= { 
                unit: 'px', // default, can be 'px' or '%'
                x: infoFaceComparison?.bbox_faces1[0][0],
                y: infoFaceComparison?.bbox_faces1[0][1],
                width: width,
                height: height};
            const croppedImg = await getCroppedImgFinal(file, crop, "myFile");
            setImgCrop(URL.createObjectURL(croppedImg));
        }
        if(!isEmpty(infoFaceComparison?.bbox_faces2)){
            const width=  infoFaceComparison?.bbox_faces2[0][2]- infoFaceComparison?.bbox_faces2[0][0];
            const height=  infoFaceComparison?.bbox_faces2[0][3]- infoFaceComparison?.bbox_faces2[0][1];
            const crop= { 
                unit: 'px', // default, can be 'px' or '%'
                x: infoFaceComparison?.bbox_faces2[0][0],
                y: infoFaceComparison?.bbox_faces2[0][1],
                width: width,
                height: height};
            const croppedImg = await getCroppedImgFinal(imgLive?.raw, crop, "myFileLive");
            setImgCropLive(URL.createObjectURL(croppedImg));
        }
    }, [infoFaceComparison]);
    
    return <ResultCompareComponent
        handleClickBack= {handleClickBack} handleClickFinish={handleClickFinish}
        checkResult= {checkResult} handleClickCheckResult={handleClickCheckResult}
        imgCrop={imgCrop} imgCropLive={imgCropLive} handleClickBackFaceCompare={handleClickBackFaceCompare}
        error={error} {...props} />;
};

const mapStateToProps = (state) => ({
    infoCMND: state.identify.infoCMND,
    errorCMND: state.identify.errorCMND,
    isPostingCMND: state.identify.isPostingCMND,
    isPostingFaceComparison: state.face.isPostingFaceComparison,
    infoFaceComparison: state.face.infoFaceComparison,
    errorFaceComparison: state.face.errorFaceComparison,
});

const mapDispatchToProps = (dispatch) => ({
    postCMNDCreators: bindActionCreators(identifyActions, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withRouter, withConnect)(ResultCompareContainer);