/* eslint-disable react/prop-types */
import React from 'react';
import { useStyles, BootstrapButton } from './styles';
import {Grid, Paper, Typography, Box, TextField, Dialog,
    DialogContent, DialogContentText } from '@material-ui/core';
import errorImg from 'assets/images/error.png';
import warningImg from 'assets/images/warning.png';
import { useEffect } from 'react';
import { ThemeProvider } from '@material-ui/styles';
import { createTheme } from '@material-ui/core';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { assets } from '../../assets';
import ColRightLayout from '../ColRightLayout';

const ConfirmInfoComponent= (props)=>{
    const {error, infoCMND, 
        imgCMND1, imgCMND2, 
        imgLive, handleBackRedo,
        handleNext,
        imgDialog, imgDialogOpen,
        handleClickImage, handlImgDialogClose,}= props;
    const classes = useStyles();
    const [openError, setOpenError] = React.useState(false);

    useEffect(()=> {
        if(error){
            setOpenError(true);
        }
    }, [error]);
  
    const handleCloseError = () => {
        setOpenError(false);
    };
    const baseTheme = createTheme();
    const isMobile = useMediaQuery(baseTheme.breakpoints.down('xs'), {
        defaultMatches: true
    });

    return (
        <ThemeProvider theme={baseTheme}>
            <Box>
                <Grid container direction='row' justifyContent='center'>
                    <Grid item xs={12} sm={12} md={1} lg={2} className={classes.gridItemSecondNone}>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItemFirst}>
                        <Paper className={classes.paperFirst}>
                            <div className={classes.cameraDiv}>
                                <div style={{flex: 1}}>
                                    <Typography className={classes.textIdentify}>Xác thực thông tin</Typography>
                                </div>
                                <div style={{flex: 5}}>
                                    <Grid container spacing={isMobile ? 0 : 3} className={classes?.divInformation}>
                                        <Grid item sm={6}>
                                            <TextField fullWidth
                                                className={classes.textField}
                                                id="filled1"
                                                label="Số CMND/CCCD"
                                                defaultValue={infoCMND?.data && infoCMND?.data?.id}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="filled"
                                            />
                                            <TextField fullWidth
                                                className={classes.textField}
                                                id="filled2"
                                                label="Họ và tên"
                                                defaultValue={infoCMND?.data && infoCMND?.data?.name}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="filled"
                                            />
                                        </Grid>
                                        <Grid item sm={6}>
                                            <TextField fullWidth
                                                className={classes.textField}
                                                id="filled3"
                                                label="Ngày cấp"
                                                defaultValue={infoCMND?.data && infoCMND?.data?.issue_date}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="filled"
                                            />
                                            <TextField fullWidth
                                                className={classes.textField}
                                                id="filled4"
                                                label="Ngày sinh"
                                                defaultValue={infoCMND?.data && infoCMND?.data?.birthday}
                                                InputProps={{
                                                    readOnly: true,
                                                }}
                                                variant="filled"
                                            />
                                        </Grid>
                                    </Grid>
                                </div>
                                {!error?.msg && <div style={{flex: 1}}>
                                    <div style={{display:"flex", justifyContent:'center'}}>
                                        <BootstrapButton className={classes.btnInCorrect} onClick={handleBackRedo}>
                        Chưa Chính Xác
                                        </BootstrapButton>
                                        <BootstrapButton className={classes.btnCorrect} onClick={handleNext}>
                        Chính Xác
                                        </BootstrapButton>
                                    </div>
                                </div>} 
                            </div>
                        </Paper>
                    </Grid>
                    <Grid item xs={12} sm={12} md={4} lg={2} className={classes.gridItemSecond}>
                        <ColRightLayout
                            imageFront ={ imgCMND1 && imgCMND1?.preview }
                            imageBack ={ imgCMND2 && imgCMND2?.preview }
                            imageSelfie ={ imgLive && imgLive?.preview }
                            verifyFront={true}
                            verifyBack={true}
                            handleClickImage={handleClickImage}
                        />
                    </Grid>
                </Grid>
                {/* dialog img */}
                <Dialog className={classes.dialog}
                    open={imgDialogOpen}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <div className={classes.divCancelIcon} onClick={handlImgDialogClose}>
                        <img alt="front" src={assets?.iconClose} className={classes.cancelIcon} />
                    </div>
                    <DialogContent className={classes.dialogContent}>
                        <DialogContentText id="alert-dialog-description">
                            <img alt="front" src={imgDialog} className={classes.imgShow} />
                        </DialogContentText>
                    </DialogContent>
                </Dialog>

                {/* dialog error */}
                {error?.msg  &&  <Dialog className={classes.dialog}
                    open={openError}
                    aria-labelledby="alert-dialog-title"
                    aria-describedby="alert-dialog-description">
                    <div className={classes.divCancelIcon} onClick={handleCloseError}>
                        <img alt="front" src={assets?.iconClose} className={classes.cancelIcon} />
                    </div>
                    <Paper className={classes.dialogPaper}>
                        <Grid container className={classes.dialogGrid}>
                            <div className={classes.imageDiv}>
                                <img className={classes.imageError} alt="cancel" 
                                    src={error?.type==="error" ? errorImg : (error?.type==="warning" ? warningImg : "")} />
                            </div>
                            <Typography className={classes.headerError}>{error?.type==="error" ? 'Lỗi hệ thống' : 
                                (error?.type==="warning" && error?.title ? error?.title : "Thông báo")}</Typography>
                            <Typography className={classes.textError}>
                                {error?.msg  || ''}</Typography>
                            {
                                error?.type==="error" && error?.code &&
                                <Typography className={classes.textError}>{`Mã code: ${error?.code}`}</Typography>
                            }
                            <BootstrapButton className={classes.btnRedo} onClick={handleBackRedo}>
                        Thực hiện lại
                            </BootstrapButton>
                        </Grid>
                    </Paper>
                </Dialog>}    
            </Box>
        </ThemeProvider>
    );
};

export default ConfirmInfoComponent;