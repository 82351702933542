import * as faceConstant from '../constants/face';

/** post face */
export const fetchPostFaceComparison = (params = {}) => ({
    type: faceConstant.FETCH_POST_FACE_COMPARISON,
    payload: {
        params,
    }
});

export const fetchPostFaceComparisonSuccess = (data) => ({
    type: faceConstant.FETCH_POST_FACE_COMPARISON_SUCCESS,
    payload: {
        data,
    },
});

export const fetchPostFaceComparisonFailed = (error) => ({
    type: faceConstant.FETCH_POST_FACE_COMPARISON_FAILED,
    payload: {
        error,
    },
});
