import { put, takeLatest, call, delay } from "redux-saga/effects";
import { FETCH_POST_FACE_COMPARISON } from "../constants/face";
import {
    fetchPostFaceComparisonSuccess,
    fetchPostFaceComparisonFailed,
} from "../actions/face";
import { fetchPostFaceComparisonApi } from "../apis/face";
import { STATUS_CODE } from "../constants/commons";
import { isEmpty } from "commons/shared";

function* actionFetchPostFaceComparison (action) {
    try {
        yield delay(500);
        const { params } = action.payload;
        const response = yield call(fetchPostFaceComparisonApi, params);
        const { status, data } = response;
        // console.log("response face: ", response);
        if (!isEmpty(data)) {
            yield put(fetchPostFaceComparisonSuccess(data));
        } else {
            yield put(fetchPostFaceComparisonFailed(true));
        }
    } catch (error) {
        yield put(fetchPostFaceComparisonFailed(error));
    }
}

export function* watchFaceFetch () {
    yield takeLatest(FETCH_POST_FACE_COMPARISON, actionFetchPostFaceComparison);
}