import StraightImage from './straightImage.svg';
import StraightIcon from './straightIcon.svg';
import LeftIcon from './leftIcon.svg';
import RightIcon from './rightIcon.svg';
import BlinkIcon from './blinkIcon.svg';
import SmileIcon from './smileIcon.svg';
import WrongImage from './wrong.svg';
import AntiGlareIdentityCard from './antiGlareIdentityCard.svg';
import OriginalIdentityCard from './originalIdentityCard.svg';
import CutIdentityCard from './cutIdentityCard.svg';

export const Svg = {
    StraightImage, LeftIcon, RightIcon, BlinkIcon, SmileIcon, WrongImage,
    AntiGlareIdentityCard, OriginalIdentityCard, CutIdentityCard, StraightIcon
};