import { makeStyles, withStyles, styled } from "@material-ui/core/styles";
import blue from "@material-ui/core/colors/blue";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import { colors } from "constants/colors";

export const useStyles = makeStyles((theme) => ({
    cameraContainer: {
        display: "flex",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%"
    },
    textProgress: {
        color: colors?.primaryP2,
        fontWeight: "bold",
        fontSize: "25px",
        textAlign: "center",
        paddingBottom: 20
    },
    cameraDiv: {
        display: "flex",
        flexDirection: 'column',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%",
    },
    cameraPaper: {
        width: "100%",
        minHeight: "284px",
        marginBottom: 15,
        elevation: 8,
        borderWidth: 1, borderStyle: 'solid', borderColor: "#D0D0D0",
        boxShadow: `1px 2px 3px #cccccc`,
    },
    img: {
        height: "271px",
        resize: 'cover',
        [theme.breakpoints.down('sm')]: {
            resize: 'cover',
            height: "200px",
        },
        [theme.breakpoints.down('xs')]: {
            resize: 'cover',
            height: "140px",
        }
    },
    btnNext: {
        borderRadius: "6px",
        color: "white",
        fontWeight: "bold",
        backgroundColor: colors?.primaryP2,
        fontSize: 16,
        "&:hover": {
            backgroundColor: colors?.primaryP3,
        },
        marginTop: 20,
        marginBottom: 10,
        padding: '10px 44px',
        [theme.breakpoints.down('xs')]: {
            padding: '6px 20px',
        }
    },
    textInstruction: {
        width: '100%',
        textAlign: 'center',
        fontFamily: "Roboto",
        padding: '22px 20px 30px 20px',
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            paddingTop: 10,
            fontSize: 16,
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            paddingTop: 10,
            fontSize: 16,
        }
    },
    headerInstruction: {
        fontFamily: "Roboto",
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 23,
        margin: 0, padding: 0,
        [theme.breakpoints.down('sm')]: {
            fontSize: 18,
        },
        [theme.breakpoints.down('xs')]: {
            fontSize: 18,
        }
    },
    textIdentify: {
        fontFamily: "Roboto",
        fontWeight: "bold",
        padding: 15, fontSize: 16,
        borderBottom: '0.5px solid #DADBDD'
    },
    paperShow: {
        height: "100%",
        backgroundColor: "#E7E7E7",
        marginTop: 20,
        marginRight: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            height: "100%",
            marginRight: 5,
            marginBottom: 5,
        },
    },
    divShow: {
        flex: 3,
        height: "100%",
        width: "100%",
        borderBottom: "1px solid #CACACA",
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    divShowLast: {
        flex: 3,
        height: "100%",
        width: "100%",
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    textShow: {
        fontFamily: "Roboto",
        textAlign: 'center',
        paddingTop: 10,
        paddingBottom: 0,
        color: colors?.textT10,
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 15,
            paddingBottom: 10,
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 15,
            paddingBottom: 10,
        },
    },
    imgShow: {
        height: '100%',
        resizeMode: 'cover'
    },
    imgDialogShow: {
        width: '100%',
        height: '100%',
        padding: 10,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center'
    },
    boxBorder: {
        position: "absolute",
        top: "8%",
        left: 0,
        width: "100%",
        height: "100%",
        [theme.breakpoints.down('xs')]: {
            top: "25%",
        }
    },
    boxBorderWrapper: {
        position: "relative",
        maxWidth: "360px",
        maxHeight: "253px",
        width: '80%',
        height: '80%',
        display: "table",
        margin: 'auto auto auto auto',
        border: "5px solid #FFC25E",
        borderRadius: "10px",
        [theme.breakpoints.down('xs')]: {
            maxWidth: "286px",
            maxHeight: "201px",
            width: '90%',
            height: '80%',
        }
    },
    boxBorderImg: {
        // position: "absolute",
        // top: "6%",
        // left: 0,
        maxWidth: "406px",
        height: '295px',
        width: '90%',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        // margin:'12px auto 12px auto',
        // height: "50%",
        [theme.breakpoints.down('xs')]: {
            // top: "23%",
            maxWidth: "348px",
            height: '440px',
            width: '100%'
        }
    },
    boxBorderWrapperImg: {
        position: "relative",
        margin: 'auto',
        maxWidth: "360px",
        maxHeight: "253px",
        width: '90%',
        height: '85%',
        display: "table",
        border: "5px solid #FFC25E",
        borderRadius: "10px",
        [theme.breakpoints.down('xs')]: {
            maxWidth: "286",
            maxHeight: "195px",
            width: '95%',
            height: '96%',
        }
    },
    edge1: {
        position: "absolute",
        top: "15px",
        left: "15px",
    },
    edge2: {
        position: "absolute",
        top: "15px",
        right: "15px",
    },
    edge3: {
        position: "absolute",
        bottom: "15px",
        right: "15px",
    },
    edge4: {
        position: "absolute",
        bottom: "15px",
        left: "15px",
    },
    containerConstructor: {
        display: 'flex',
        justifyContent: 'center',
        paddingTop: 20,
        // margin: theme.spacing(7),
        // marginLeft:200,
        // marginRight:200,
    },
    containerImg: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        width: '100%'
    },
    detailInstructor: {
        display: 'flex',
        justifyContent: 'center',
        // margin: theme.spacing(7),
    },
    btnCamera: {
        borderRadius: "6px",
        color: "white",
        fontWeight: "bold",
        backgroundColor: colors?.primaryP2,
        "&:hover": {
            backgroundColor: colors?.primaryP3,
        },
        marginTop: 30,
        padding: '9px 34px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 20,
            padding: '6px 20px',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            padding: '6px 20px',
        }
    },
    btnOnlyCamera: {
        borderRadius: "6px",
        color: "white",
        fontWeight: 700,
        backgroundColor: colors?.primaryP2,
        "&:hover": {
            backgroundColor: colors?.primaryP3,
        },
        marginTop: 85,
        padding: '9px 34px',
        [theme.breakpoints.down('sm')]: {
            padding: '6px 20px 6px 20px',
        },
        [theme.breakpoints.down('xs')]: {
            padding: '6px 20px 6px 20px',
        }
    },
    divInstructor: {
        flex: 5,
        marginBottom: 50,
        width: "400px",
    },
    paperInstructor: {
        flex: 2,
        // width:"400px",
        width: "420px",
        margin: 10,
        [theme.breakpoints.down('sm')]: {
            width: "350px",
        }
    },
    paperInstructorNew: {
        flex: 2,
        // width:"400px",
        width: "416px",
        height: '90px',
        margin: '9px 16px 9px 16px',
        padding: '10px 56px 10px 56px',
        display: 'flex', flexDirection: 'row',
        alignItems: 'center', justifyContent: 'center',
        elevation: 4,
        boxShadow: '1px 2px 3px #cccccc',
        borderRadius: 6,
        [theme.breakpoints.down('sm')]: {
            width: "350px",
            margin: '8px 8px 8px 8px',
            padding: '10px 15px 10px 15px',
        }
    },
    gridImg1: {
        paddingRight: 30,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0
        }
    },
    gridImg2: {
        paddingRight: 30,
        marginTop: 'auto',
        [theme.breakpoints.down('sm')]: {
            paddingRight: 0,
        }
    },
    paperFlexInstructor: {
        display: 'flex',
        flexDirection: 'row',
        padding: 10
    },
    divImg: {
        height: '317px',
        width: '100%',
        padding: '10px 15px 10px 10px',
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            height: '100%',
            padding: '0px 15px 10px 10px',
        }
    },
    divImgRight: {
        padding: '15px 0px 20px 0px',
        maxWidth: '251px',
        maxHeight: '165px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            marginBottom: 15,
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            marginBottom: 15,

        }
    },
    gridRowCenter: {
        display: 'flex', flexDirection: 'row',
        justifyContent: 'center'
    },
    gridItemFirst: {
        marginLeft: '45px !important',
        marginRight: '45px !important',
        [theme.breakpoints.down('md')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        }
    },
    gridItemSecondNone: {
        // marginLeft:'150px !important', 
        [theme.breakpoints.down('md')]: {
            display: 'none',
            marginRight: '30px !important',
            marginLeft: '30px !important',
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        }
    },
    gridItemSecond: {
        // marginLeft:'150px !important', 
        [theme.breakpoints.down('md')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        }
    },
    divSecond: {
        marginTop: 20,
        marginBottom: 20,
        // backgroundColor: '#E7E7E7',
        backgroundColor: colors?.backgroundB2,
        borderRadius: '10px',
    },
    imgCapture: {
        height: '317px',
        padding: 10,
        [theme.breakpoints.down('xs')]: {
            height: '100%',
        }
    },
    btnInCorrect: {
        marginTop: 30,
        marginBottom: 30,
        borderRadius: "6px",
        color: "#757474",
        fontWeight: "bold",
        backgroundColor: "#FFFFFF",
        marginRight: 20,
        padding: '9px 34px',
        border: '1px solid #DADBDD',
        [theme.breakpoints.down('sm')]: {
            // padding: '5px 14px',
            padding: '6px 20px',
            marginTop: 20,
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            padding: '6px 20px',
        }
    },
    btnCorrect: {
        marginTop: 30,
        marginBottom: 30,
        borderRadius: "6px",
        color: "white",
        fontWeight: "bold",
        backgroundColor: colors?.primaryP2,
        "&:hover": {
            backgroundColor: colors?.primaryP3,
        },
        padding: '10px 34px',
        [theme.breakpoints.down('sm')]: {
            // padding: '5px 14px',
            marginTop: 20,
            padding: '6px 20px',
        },
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
            padding: '6px 20px',
        }
    },
    paperEmpty: {
        height: '165px',
        backgroundColor: '#E7E7E7',
    },
    divCancelIcon: {
        position: 'absolute',
        right: 15,
        marginTop: -20,
        cursor: 'pointer'
    },
    cancelIcon: {
        width: 33,
        height: 33,
        backgroundColor: '#ffffff',
        borderRadius: 30,
        position: 'fixed'
    },
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: "10px !important"
        },

    },
    dialogContent: {
        padding: 40,
        [theme.breakpoints.down('sm')]: {
            padding: 15,
        },
        [theme.breakpoints.down('xs')]: {
            padding: 15,
        }
    },
    containerSecond: {
        display: "flex",
        justifyContent: "center"
    },
    dialogPaper: {
        backgroundColor: "#FFFFFF",
        paddingTop: 30,
        paddingBottom: 30,
        paddingRight: 150,
        paddingLeft: 150,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 100,
            paddingLeft: 100,
        }
    },
    dialogGrid: {
        alignItems: "center",
        textAlign: 'center',
    },
    headerError: {
        color: "#121212",
        fontFamily: "Roboto",
        fontWeight: "bold",
    },
    textError: {
        fontFamily: "Roboto",
        color: "#706F6F",
        marginTop: 10,
        marginBottom: 10,
    },
    btnRedo: {
        borderRadius: "6px",
        color: "white",
        fontWeight: "bold",
        backgroundColor: colors?.primaryP2,
        "&:hover": {
            backgroundColor: colors?.primaryP3,
        },
        marginTop: 20,
        marginBottom: 20,
        padding: '15px 54px',
        [theme.breakpoints.down('sm')]: {
            padding: '5px 24px',
        }
    },
    paperNotCamera: {
        background: 'linear-gradient(135.65deg, #F4F5FA 14.65%, rgba(235, 238, 243, 0.1) 85.1%)',
        backdropFilter: 'blur(40px)',
        borderRadius: '10px',
        padding: '50px',
        marginTop: '5%',
        marginBottom: '5%',
        marginLeft: '10%',
        marginRight: '10%',
        display: "flex",
        flexDirection: "row"
    },
    imgDiv: {
        flex: 1,
        [theme.breakpoints.down('sm')]: {
            flex: 3,
        }
    },
    btnLink: {
        borderRadius: '37px',
        marginBottom: '5%',
        width: "80%",
        marginLeft: '10%',
        marginRight: '10%',
        display: "flex",
        flexDirection: "row",
        justifyContent: 'space-between'
    },
    copyText: {
        color: colors?.primaryP2,
        fontWeight: "bold"
    },
    imgDivInstructor: {
        marginLeft: 27,
        marginRight: 27,
        display: 'flex', flexDirection: 'column',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            marginLeft: 0,
            marginRight: 0,
            marginTop: '20px',
            width: '45%'
            // marginBottom: '20px',
        },
        [theme.breakpoints.down('xs')]: {
            marginLeft: 2,
            marginRight: 2,
            marginTop: '20px',
            width: '45%'
            // marginBottom: '20px'
        }
    },
    imgIcon: {
        height: 60,
        [theme.breakpoints.down('sm')]: {
            height: 40,
        },
    },
    imgIconNew: {
        height: 53,
        width: 74,
        resize: 'cover',
        marginRight: 10,
        [theme.breakpoints.down('sm')]: {
            height: 40,
            width: 56
        },
    },
    txtTitle: {
        color: '#000000', opacity: 0.7,
        display: 'flex', flexWrap: 'wrap',
        fontSize: 18,
        [theme.breakpoints.down('sm')]: {
            fontSize: 16,
        },
    },
    iconCamera: {
        height: 30,
        paddingRight: 8,
        [theme.breakpoints.down('sm')]: {
            height: 24,
        }
    },
    divPreviewImage: {
        display: 'flex',
        flexDirection: "column",
        justifyContent: 'center',
        alignItems: 'center',
        position: "relative"
    }
}));

export const BootstrapButton = withStyles({
    root: {
        textTransform: "none",
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
})(Button);

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
