/* eslint-disable react/prop-types */
import React from 'react';
import { useStyles, BootstrapButton } from './styles';
import {
    Grid, Paper, Typography, Box, Dialog,
    DialogContent, DialogContentText, Avatar,
} from '@material-ui/core';
import warningImg from 'assets/images/warning.png';
import errorImg from 'assets/images/error.png';
import Webcam from "react-webcam";

import { assets } from '../../assets';
import ColRightLayout from '../ColRightLayout';

const LiveComponent = (props) => {
    const {
        imgCMND1, imgCMND2,
        webcamRef, canvasRef,
        randomStep,
        onUserMediaChange,
        onUserMediaErrorChange,
        runStep,
        errorLive,
        successLive,
        imgLive,
        valueProgress,
        loadingTime,
        enableWebcam,
        handleRetryCamera,
        imgDialog, imgDialogOpen,
        handleClickImage, handlImgDialogClose,
        openDialog, handleCloseDialog,
        progress, handleClickFinish, straightFace
    } = props;
    const classes = useStyles();
    const [process, setProcess] = React.useState(0);
    const [processImg, setProcessImg] = React.useState(0);
    return (
        <Box>
            <Grid container direction='row' justifyContent='center'>
                <Grid item xs={12} sm={12} md={1} lg={2} className={classes.gridItemSecondNone}>
                </Grid>
                <Grid item xs={12} sm={12} md={6} lg={6} className={classes.gridItemFirst}>
                    <Paper className={classes.paperFirst}>
                        <div className={classes.cameraDiv}>
                            <div className={classes.divLiveFirst}>
                                <Typography className={classes?.textTitle}>Hãy làm theo các động tác dưới đây để hệ thống hiểu được bạn là người thật</Typography>
                            </div>
                            <div className={classes.divLiveSecond}>
                                <Grid container className={classes.containerLive}>
                                    <Grid item className={classes.liveness}>
                                        <Box className={classes.boxAvatar}>
                                            <Avatar
                                                className={classes.avatar}
                                                alt="Remy Sharp"
                                            >
                                                {successLive === "Thành công tất cả các bước" && imgLive.preview
                                                    ? (
                                                        <img
                                                            alt="img"
                                                            src={imgLive.preview}
                                                            style={{ height: "100%" }}
                                                        />
                                                    ) : (
                                                        enableWebcam &&
                                                        <>
                                                            <Webcam
                                                                id="fs_video"
                                                                ref={webcamRef}
                                                                audio={false}
                                                                screenshotFormat="image/jpeg"
                                                                className={classes.webcam}
                                                                onUserMedia={onUserMediaChange}
                                                                onUserMediaError={onUserMediaErrorChange}
                                                            />
                                                            <div style={{ ...styles?.divLayerTop, ...styles?.divLayerFace }}>
                                                                {runStep >= 0 && runStep <= 2 &&
                                                                    <img key={randomStep[runStep]?.name}
                                                                        src={assets[randomStep[runStep]?.layer]}
                                                                        style={{ height: '100%', resize: 'contain' }}
                                                                    />
                                                                }
                                                            </div>
                                                            {(loadingTime) && (
                                                                <div
                                                                    className="lds-ellipsis"
                                                                    style={styles?.divLayerTop}
                                                                >
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                    <div></div>
                                                                </div>
                                                            )}
                                                            <canvas
                                                                id="fs_canvas"
                                                                ref={canvasRef}
                                                                className={classes.webcam}
                                                                style={styles?.canvas}
                                                            />
                                                        </>
                                                    )}
                                            </Avatar>
                                            {
                                                (enableWebcam || imgLive?.preview) &&
                                                <img
                                                    src={assets[`process_${valueProgress}`]}
                                                    className={`${classes.svgProcess} process${valueProgress}`}
                                                />
                                            }
                                        </Box>
                                    </Grid>
                                </Grid>
                            </div>
                            <div className={classes.divLiveThird}>
                                <div key={randomStep[runStep]?.name}>
                                    {!loadingTime && runStep >= 0 && runStep <= 2 && <div className={classes.liveInstructor}>
                                        <div style={{ flex: 5 }}>
                                            <img alt="left" src={randomStep[runStep]?.img} style={{ height: "50px" }} />
                                        </div>
                                        <div style={{ flex: 5, paddingTop: 5 }}>
                                            <Typography className={classes?.textAction}>{randomStep[runStep].text}</Typography>
                                        </div>
                                    </div>}
                                    {
                                        loadingTime &&
                                        <div style={{ flex: 5 }}>
                                            <Typography className={classes?.textTitle}>Đang tải xuống model,
                                                vui lòng chờ và thực hiện theo các hành động trên màn hình...</Typography>
                                        </div>
                                    }
                                </div>
                            </div>
                        </div>
                    </Paper>
                </Grid>
                <Grid item xs={12} sm={12} md={4} lg={2} className={classes.gridItemSecond}>
                    <ColRightLayout
                        imageFront={imgCMND1 && imgCMND1?.preview}
                        imageBack={imgCMND2 && imgCMND2?.preview}
                        imageSelfie={imgLive && imgLive?.preview}
                        verifyFront={true}
                        verifyBack={true}
                        isLiveness={true}
                        valueProgress={progress}
                        handleClickImage={handleClickImage}
                    />
                </Grid>
            </Grid>
            {/* dialog img */}
            <Dialog className={classes.dialog}
                open={imgDialogOpen}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div className={classes.divCancelIcon} onClick={handlImgDialogClose}>
                    <img alt="front" src={assets?.iconClose} className={classes.cancelIcon} />
                </div>
                <DialogContent className={classes.dialogContent}>
                    <DialogContentText id="alert-dialog-description">
                        <img alt="front" src={imgDialog} className={classes.imgShow} />
                    </DialogContentText>
                </DialogContent>
            </Dialog>

            {/* dialog error */}
            {errorLive?.msg && <Dialog className={classes.dialog}
                open={openDialog}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description">
                <div className={classes.divCancelIcon} onClick={handleCloseDialog}>
                    <img alt="front" src={assets?.iconClose} className={classes.cancelIcon} />
                </div>
                <Paper className={classes.dialogPaper}>
                    <Grid container direction="column" className={classes.dialogGrid}>
                        <div className={classes.imageDiv}>
                            <img className={classes.imageError} alt="cancel"
                                src={errorLive?.type === "error" ? errorImg : warningImg} />
                        </div>
                        <Typography className={classes.headerError}>
                            {errorLive?.type === "error" ? "Lỗi hệ thống" : "Thông báo"}</Typography>
                        <Typography className={classes.textError}>
                            {errorLive.msg || ''}</Typography>
                        <BootstrapButton className={classes.btnRedo}
                            onClick={handleRetryCamera}>
                            {errorLive?.type === "redo" || errorLive?.type === "error" ? "Thực hiện lại" : "Đồng ý"}
                        </BootstrapButton>
                    </Grid>
                </Paper>
            </Dialog>}
        </Box>
    );
};

export default LiveComponent;

const styles = {
    divLayerTop: {
        position: "absolute",
        textAlign: "center",
        justifyContent: "center",
        zIndex: 3000,
        transform: "scaleX(-1)",
    },
    divLayerFace: {
        width: '80%',
        height: '80%',
    },
    canvas: {
        position: "absolute",
        marginLeft: "auto",
        marginRight: "auto",
        left: 0,
        right: 0,
        textAlign: "center",
        zIndex: 9,
        transform: "scaleX(-1)",
    }
};