/* eslint-disable no-redeclare */
/* eslint-disable react/prop-types */
import React, {useEffect, useRef, useState} from 'react';
import LivenessComponent from 'components/Liveness/index';
import ResultCompareContainer from 'containers/ResultCompare';
import * as facemesh from '@tensorflow-models/face-landmarks-detection';
import '@tensorflow/tfjs-backend-webgl';
import '@tensorflow/tfjs-backend-cpu';
import * as THREE from "three";
import {
    TIMEOUT, TIME_STEP, TIME_INTERVAL,
    ERR_MESSAGE_NOT_ALLOW, ERR_MESSAGE_MANY_FACES,
    TOTAL_TIME, randomStepResponse
} from 'constants/liveness';
import { isEmpty} from 'commons/shared';
import { withRouter } from "react-router";
import { bindActionCreators, compose } from "redux";
import { connect } from "react-redux";
import * as faceActions from 'actions/face';
import * as identifyActions from 'actions/identify';

const LivenessContainer= (props)=>{
    const {imgLive, handleImgLive,
        postFaceCreators, infoCMND,
        infoFaceComparison, handleErrorLiveness,
        errorFaceComparison, handleReset,
        postCMNDCreators,DetectNet
    }= props;
    const {fetchPostFaceComparison } = postFaceCreators;
    const { fetchResetInitial } = postCMNDCreators;
    
    const webcamRef = useRef(null);
    const canvasRef = useRef(null);

    //randomStep 
    const [randomStep, setRandomStep]= useState([]);
    //
    const [errorLive, setErrorLive]= useState({ msg: "",type: ""});
    const [successLive, setSuccessLive]= useState("");
    const [openDialog, setOpenDialog] = useState(false);
    const [runActiveStep, setRunActiveStep]= useState(0);
    const [valueProgress, setValueProgress]= useState(0);
    const [progress, setProgress] = React.useState(0);
    const [loadingTime, setLoadingTime]= useState(false);
    const [timer, setTimer]= useState("");
    const [enableWebcam, setEnableWebcam]= useState(false);
    const [errorTime, setErrorTime]= useState(false);
    const [instructor, setInstructor]= useState(true);
    const [timeLeft, setTimeLeft] = useState(TOTAL_TIME);
    const [startCtx, setStartCtx]= useState(false);
    var timeouts= [];
    var RUN_EKYC = 0;
    // var DetectNet = null;
    var refreshLoop = null;
    var runStep = 0;
    var checkObj = [];
    var straightFace = { isStraight: false, coordinate:{}, isMoving:false, count:0};
    var smileFace = { isSmile: false,count:0};
    var blinkFace = { isBlink: false,count:0,coordinate:{}};
    var checkSmile = 0;
    var minLip = 0;

    const handleClickFinish = () => {
        handleReset();
        if (fetchResetInitial) {
            fetchResetInitial();
        }
    };

    const getRandomStep = ()=>{
        const randomTmp = randomStepResponse();
        setRandomStep(randomTmp);
    };

    useEffect(() => {
        if(randomStep && Array.isArray(randomStep) && randomStep.length==0)
            getRandomStep();
    }, []);

    useEffect(() => {
        if (startCtx && timeLeft > 0 && runActiveStep <3) {
            const intervalId = setInterval(() => {
                setTimeLeft((t) => t - 1);
            }, 1000);
            return () => clearInterval(intervalId);
        } else if(startCtx && timeLeft === 0 && runActiveStep <3){
            // resetProcess();
            setErrorLive({
                msg: "Đã hết thời gian cho phép thực hiện kiểm tra người thật.Quý khách vui lòng thực hiện lại",
                type: "redo"
            });
        }
    }, [timeLeft, startCtx, runActiveStep]);

    useEffect(()=> {
        if(errorLive && errorLive?.type){
            setOpenDialog(true);
        }
    }, [errorLive]);
    
    useEffect(()=> {
        if(valueProgress===99 && imgLive?.preview){
            postFaceComparison();
        }
    }, [imgLive, valueProgress]);

    const postFaceComparison = async () => {
        const imgCMND= infoCMND?.data?.image_roi;
        const newImgCMND= `data:image/jpeg;base64,${imgCMND}`;
        const blob = await fetch(newImgCMND).then((res) => res.blob());
        var file = new File([blob], "imgCMND.jpg");
        const image2= imgLive?.raw;
        const formData= new FormData();
        formData.append("image1", file);
        formData.append("image2", image2);
        const dataForSend= {};
        dataForSend.formData= formData;
        dataForSend.requestId= infoCMND?.request_id || '';
        if(fetchPostFaceComparison){
            fetchPostFaceComparison(dataForSend);
        }
    };

    useEffect(()=> {
        if(errorFaceComparison){
            setErrorLive({ 
                msg: "Có lỗi hệ thống xảy ra, quý khách vui lòng thực hiện lại quá trình eKYC",
                type: "error"
            });
        }
    }, [errorFaceComparison]);
    
    const handleCloseDialog = () => {
        setOpenDialog(false);
        setErrorLive({ msg: "",type: ""});
    };

    const handleRetry= ()=>{
        setOpenDialog(false);
        setTimeLeft(TOTAL_TIME);
    };

    useEffect(() => {
        if(DetectNet && randomStep.length>0){
            runFacemesh();
        }
    }, [DetectNet, randomStep]);

    useEffect(()=>{
        setEnableWebcam(true);
        if(successLive==="Thành công tất cả các bước"){
            if(!isEmpty(timeouts)){
                for (var i=0; i<timeouts.length; i++) {
                    clearTimeout(timeouts[i]);
                }
            }
        }
    }, []);
    /** start capture */
    const capture = React.useCallback(
        async () => {
            if(webcamRef.current !== null){
                const imageSrc = webcamRef.current.getScreenshot();
                const blob = await fetch(imageSrc).then((res) => res.blob());
                var file = new File([blob], "imgLiveness.jpg");
                handleImgLive({
                    preview: URL.createObjectURL(blob),
                    raw: file
                });
                return imageSrc;
            }
        },
        [webcamRef]
    );
    /** end capture */

    /** start run facemesh */
    const runFacemesh = async () => {
        RUN_EKYC= 0;
        setLoadingTime(true);
        // optionsFaceMesh = {
        //     inputResolution: { width: "100%", height: "100%" },
        //     // scale: 0.8,
        //     maxFaces: 1
        // };
        if(DetectNet){
            detectFaceStep(DetectNet);
        }
    };
    /** end run facemesh */

    /** start detect face step */
    const detectFaceStep = (net) => {
        if (runStep > 2 && !openDialog) {
            successStep();
            // nextStep();
        } else if(valueProgress === 99 && !openDialog){
            successStep();
        } else {
            // detect(net);
            refreshLoop = setInterval(() => {
                detect(net);
            }, TIME_INTERVAL);
        }
    };
    /** end detect face step */

    /** start detect */
    const detect = async (net) => {
        if (typeof webcamRef.current !== "undefined" && webcamRef.current !== null 
            && webcamRef.current.video.readyState === 4 && canvasRef.current !== null ) {
            // Get Video Properties
            const video = webcamRef.current.video;
            const videoWidth = webcamRef.current.video.videoWidth;
            const videoHeight = webcamRef.current.video.videoHeight;
            // Set video width
            webcamRef.current.video.width = videoWidth;
            webcamRef.current.video.height = videoHeight;
            // Set canvas width
            canvasRef.current.width = videoWidth;
            canvasRef.current.height = videoHeight;
            // Make Detections
            // const face = await net.estimateFaces(video);
            const face = await net.estimateFaces({input: video, predictIrises: true});
            setLoadingTime(false);
            RUN_EKYC = RUN_EKYC + TIME_INTERVAL;
            const ctx = canvasRef?.current?.getContext("2d");
            if(ctx && !openDialog && Array.isArray(randomStep) && randomStep.length>0){
                setStartCtx(true);
                // requestAnimationFrame(()=>{drawMesh(face, ctx);});
                runDetectMesh(face, ctx);
            }
        }
       
    };
    /** end detect */

    /** start calculate distance  */
    const getDistanceToCoordinates = (x1, y1, z1, x2, y2, z2) => {
        var a = new THREE.Vector3(x1, y1, z1);
        var b = new THREE.Vector3(x2, y2, z2);
        return a.distanceTo(b);
    };
    /** end calculate distance  */

    /** start success step */
    const successStep = () => {
        setErrorLive({ msg: "",type: ""});
        setSuccessLive("Thành công tất cả các bước");
        setValueProgress(99);
        setProgress(100);
        clearInterval(refreshLoop);
        setEnableWebcam(false);
        setTimeLeft(0);
    };
    /** end success step */
    
    /** start timeout step */
    const timeoutStep = () => {
        RUN_EKYC= 0;
        clearInterval(refreshLoop);
        setEnableWebcam(false);
        setErrorTime(true);
    };
    /** end timeout step */
    // cuoi -> giong cong thuc mat (nguoc lai)
    // thang mat ket hop cua center va cuoi
    /** start check turn left right face */
    const checkFaceTurnLeftOrRight = (cheek, name) => {
        var obj = {};
        obj.name = name;
        obj.value = cheek;

        if (checkObj.length > 0) {
            if (typeof checkObj[1] === 'undefined') {
                if (name != 'center') {
                    checkObj.push(obj);
                }
            } else {
                if (typeof checkObj[2] === 'undefined') {
                    if (name == 'center') {
                        checkObj.push(obj);
                    }
                }
            }
        } else {
            if (name == 'center') {
                checkObj.push(obj);
            }
        }
        if (checkObj.length == 3) {
            // if(!imgLive.preview){
            //     capture();
            // }
            // setErrorLive({ msg: "",type: ""});
            clearInterval(refreshLoop);
            checkObj = [];
            setSuccessLive("Thành công");
            setRunActiveStep((prevStep) =>prevStep + 1);
            runStep = runStep + 1;
            setValueProgress((prevValue) =>prevValue + 33);
            setProgress((prevValue)=> prevValue+33);
            setTimeout(function () {
                RUN_EKYC= 0;
                setSuccessLive("");
                detectFaceStep(DetectNet);
            }, TIME_STEP);

        }
    };
    /** end check turn left right face */
    
    /** start check neutral face */
    // const checkFaceNeutral = (a, b, c, d) => {
    //     var done = false;
    //     var l = (a + b + c) / 3;
    //     var result = l / d;
    //     // neutral: 0.38 => 0.41
    //     if (result >= 0.385 && result <= 0.41) {
    //         done = true;
    //     }

    //     if (done) {
    //         capture();
    //         setErrorLive({ msg: "",type: ""});
    //         setSuccessLive("Giữ khuôn mặt thẳng thành công");
    //         checkObj = [];
    //         setRunActiveStep((prevStep) =>prevStep + 1);
    //         runStep = runStep + 1;
    //         setValueProgress((prevValue) =>prevValue + 33);
    //         setProgress((prevValue)=> prevValue+33);
    //         setTimeout(function () {
    //             RUN_EKYC= 0;
    //             setSuccessLive("");
    //             detectFaceStep(DetectNet);
    //         }, TIME_STEP);
    //     } 
    // };
    /** end check neutral face */

    const checkStraightFace=(annotations)=>{
        var lipsLowerOuter = annotations.lipsUpperInner[0];
        var lipsUpperOuter = annotations.lipsUpperInner[10];
        var leftEyeLower = annotations.leftEyeLower0[0];
        var rightEyeLower = annotations.rightEyeLower0[0];
        // var middleBetweenEye = annotations?.midwayBetweenEyes[0];
        var noseTip = annotations.noseTip[0];

        var leftEyeToNose = getDistanceToCoordinates(
            leftEyeLower[0], leftEyeLower[1], leftEyeLower[2],//point x,y,z
            noseTip[0], noseTip[1], noseTip[2]);
        var rightEyeToNose = getDistanceToCoordinates(
            rightEyeLower[0], rightEyeLower[1], rightEyeLower[2],//point x,y,z
            noseTip[0], noseTip[1], noseTip[2]);
                            
        var leftLipToNose = getDistanceToCoordinates(
            lipsLowerOuter[0], lipsLowerOuter[1], lipsLowerOuter[2],//point x,y,z
            noseTip[0], noseTip[1], noseTip[2]);
        var rightLipToNose = getDistanceToCoordinates(
            lipsUpperOuter[0], lipsUpperOuter[1], lipsUpperOuter[2],//point x,y,z
            noseTip[0], noseTip[1], noseTip[2]);
        //
        var leftEyeToOX = getDistanceToCoordinates(
            leftEyeLower[0], leftEyeLower[1], leftEyeLower[2],//point x,y,z
            leftEyeLower[0] ,0,  leftEyeLower[2]);  

        var rightEyeToOX = getDistanceToCoordinates(
            rightEyeLower[0], rightEyeLower[1], rightEyeLower[2],//point x,y,z
            rightEyeLower[0] ,0,  rightEyeLower[2]);
            // leftEyeToNose, , leftLipToNose, rightLipToNose, noseTip, leftEyeToOX, rightEyeToOX
        const differentEye = Math.abs(rightEyeToNose - leftEyeToNose);
        const differentLip = Math.abs(leftLipToNose - rightLipToNose);
        const differentEyeToOX = Math.abs(leftEyeToOX - rightEyeToOX);
        if(differentEye <=10 && differentLip<=10 && differentEyeToOX<10){
            return true;
        }
        return false;
    };
    //check face neutral new 
    const checkFaceNeutral = (disLeftEye, disRightEye, disLeftLip, disRightLip, coordinateNose, disLeftEyeToOX, disEightEyeToOX) => {
        const differentEye = Math.abs(disRightEye - disLeftEye);
        const differentLip = Math.abs(disLeftLip - disRightLip);
        const differentEyeToOX = Math.abs(disLeftEyeToOX - disEightEyeToOX);
        if(differentEye <=10 && differentLip<=10 && differentEyeToOX<10){
            if(straightFace && straightFace.x && straightFace.y &&
                (coordinateNose[0] - straightFace.x > 10 || coordinateNose[1] - straightFace.y > 10 )){
                straightFace = {
                    isMoving:true,
                    isStraight:true,
                    count:0,
                    coordinate: {
                        x:coordinateNose[0],
                        y:coordinateNose[1],
                        z:coordinateNose[2],
                    }
                };
            }
            straightFace={
                isMoving:false,
                isStraight:true,
                count:straightFace.count + 1 ,
                coordinate: {
                    x:coordinateNose[0],
                    y:coordinateNose[1],
                    z:coordinateNose[2],
                }
            };
        }
        if (straightFace.count >= 8) {
            capture();
            // setErrorLive({ msg: "",type: ""});
            setSuccessLive("Giữ khuôn mặt thẳng thành công");
            checkObj = [];
            runStep = runStep + 1;
            setRunActiveStep((prevStep) =>prevStep + 1);
            setValueProgress((prevValue) =>prevValue + 33);
            setProgress((prevValue)=> prevValue+33);
            setTimeout(function () {
                RUN_EKYC= 0;
                setSuccessLive("");
                detectFaceStep(DetectNet);
            }, TIME_STEP);
        } 
    };
    /** start check smile face */
    //e: distance inner upper left lips
    //f: distance inner upper right lips
    //g: distance inner upper center lips

    const checkFaceSmile = (a, b, c, d, g) => {
        var l = (a + b + c) /3;
        var result = l / d;
        // neutral: 0.38 => 0.41
        if(minLip > d || minLip===0){
            minLip = d;
        }
        if(checkSmile === 0  && d - minLip >= 15){
            checkSmile = 1;
        }else if(checkSmile > 0 && d - minLip < 15 ){
            checkSmile = 0;
            smileFace= {
                isSmile:false,
                count:0
            };
        }
        // console.log('d - minLip',d - minLip);
        // console.log('checkSmile',checkSmile);
        // console.log('g',g);
        // console.log('count',smileFace?.count);
        if(checkSmile > 0 && result >= 0.32 && g>=12){
            smileFace= {
                isSmile:true,
                count: smileFace.count + 1
            };
        }
        if (smileFace && smileFace.count>=5) {
            // setErrorLive({ msg: "",type: ""});
            setSuccessLive("Mỉm cười thành công");
            checkObj = [];
            setRunActiveStep((prevStep) =>prevStep + 1);
            runStep = runStep + 1;
            setValueProgress((prevValue) =>prevValue + 33);
            setProgress((prevValue)=> prevValue+33);
            setTimeout(function () {
                RUN_EKYC= 0;
                setSuccessLive("");
                detectFaceStep(DetectNet);
            }, TIME_STEP);
        } 
    };
    /** end check smile face */

    //check smile 
    // const checkFaceSmile= (ratioLip)=>{
    //     if((ratioLip && ratioLip>=0.62)) {
    //         setErrorLive({ msg: "",type: ""});
    //         setSuccessLive("Mỉm cười thành công");
    //         checkObj = [];
    //         setRunActiveStep((prevStep) =>prevStep + 1);
    //         runStep = runStep + 1;
    //         setValueProgress((prevValue) =>prevValue + 33);
    //         setProgress((prevValue)=> prevValue+33);
    //         setTimeout(function () {
    //             RUN_EKYC= 0;
    //             setSuccessLive("");
    //             detectFaceStep(DetectNet);
    //         }, TIME_STEP);
    //     }
    // };
    // 
    /** start check right eye blink */
    const checkEyeBlink= (ratioR, ratioL)=>{
        // return;
        if(ratioR<=0.18 || ratioL<=0.18) {
            blinkFace={
                count:blinkFace.count+1
            };
        }
        if(blinkFace.count >= 3){
            // setErrorLive({ msg: "",type: ""});
            setSuccessLive("Nháy mắt thành công");
            checkObj = [];
            setRunActiveStep((prevStep) =>prevStep + 1);
            runStep = runStep + 1;
            setValueProgress((prevValue) =>prevValue + 33);
            setProgress((prevValue)=> prevValue+33);
            setTimeout(function () {
                RUN_EKYC= 0;
                setSuccessLive("");
                detectFaceStep(DetectNet);
            }, TIME_STEP);
        }
    };
    /** end check right eye blink */
    /** start detect mesh */
    const runDetectMesh = (predictions, ctx) => {
        if (RUN_EKYC >= TIMEOUT) {
            timeoutStep();
        }
        // if(predictions && predictions.length>1){
        // };
        if (predictions.length == 1) {
            predictions.forEach((prediction) => {
                var annotations = prediction.annotations;
                var faceInViewConfidence = prediction.faceInViewConfidence;
                var boundingBox = prediction.boundingBox;
                var lipsLowerOuter = annotations.lipsLowerOuter;
                var lipsUpperOuter = annotations.lipsUpperOuter;

                if (faceInViewConfidence >= 0.6) {
                    if (typeof (randomStep[runStep]) === 'undefined') {
                        successStep();
                    } else {
                        const currentStep = randomStep[runStep].name;
                        switch (currentStep) {
                        case 'straight':
                            timeouts.push(setTimeout(()=>{setTimer("straight");}, 3000));
                            var lipsLowerOuter = annotations.lipsUpperInner[0];
                            var lipsUpperOuter = annotations.lipsUpperInner[10];
                            var leftEyeLower = annotations.leftEyeLower0[0];
                            var rightEyeLower = annotations.rightEyeLower0[0];
                            // var middleBetweenEye = annotations?.midwayBetweenEyes[0];
                            var noseTip = annotations.noseTip[0];

                            var leftEyeToNose = getDistanceToCoordinates(
                                leftEyeLower[0], leftEyeLower[1], leftEyeLower[2],//point x,y,z
                                noseTip[0], noseTip[1], noseTip[2]);
                            var rightEyeToNose = getDistanceToCoordinates(
                                rightEyeLower[0], rightEyeLower[1], rightEyeLower[2],//point x,y,z
                                noseTip[0], noseTip[1], noseTip[2]);
                            
                            var leftLipToNose = getDistanceToCoordinates(
                                lipsLowerOuter[0], lipsLowerOuter[1], lipsLowerOuter[2],//point x,y,z
                                noseTip[0], noseTip[1], noseTip[2]);
                            var rightLipToNose = getDistanceToCoordinates(
                                lipsUpperOuter[0], lipsUpperOuter[1], lipsUpperOuter[2],//point x,y,z
                                noseTip[0], noseTip[1], noseTip[2]);
                            //
                            var leftEyeToOX = getDistanceToCoordinates(
                                leftEyeLower[0], leftEyeLower[1], leftEyeLower[2],//point x,y,z
                                leftEyeLower[0] ,0,  leftEyeLower[2]);  

                            var rightEyeToOX = getDistanceToCoordinates(
                                rightEyeLower[0], rightEyeLower[1], rightEyeLower[2],//point x,y,z
                                rightEyeLower[0] ,0,  rightEyeLower[2]); 
                                
                            checkFaceNeutral(leftEyeToNose, rightEyeToNose, leftLipToNose, rightLipToNose, noseTip, leftEyeToOX, rightEyeToOX);
                            // var dis_lip_left_center = getDistanceToCoordinates(lipsUpperOuter[6][0], 
                            //     lipsUpperOuter[6][1], lipsUpperOuter[6][2], lipsLowerOuter[5][0], lipsLowerOuter[5][1], lipsLowerOuter[5][2]);
                            // var dis_lip_right_center = getDistanceToCoordinates(lipsUpperOuter[4][0], 
                            //     lipsUpperOuter[4][1], lipsUpperOuter[4][2], lipsLowerOuter[3][0], lipsLowerOuter[3][1], lipsLowerOuter[3][2]);
                            // var dis_lip_left_right = getDistanceToCoordinates(lipsUpperOuter[10][0], 
                            //     lipsUpperOuter[10][1], lipsUpperOuter[10][2], lipsUpperOuter[0][0], lipsUpperOuter[0][1], lipsUpperOuter[0][2]);
                            // checkFaceNeutral(dis_lip_left_center, dis_lip_center, dis_lip_right_center, dis_lip_left_right);
                            break;
                        case 'smile':
                            timeouts.push(setTimeout(()=>{setTimer("smile");}, 3000));
                            var lipsLowerLeftOuter = annotations.lipsLowerOuter[5];
                            var lipsUpperLeftOuter = annotations.lipsUpperOuter[6];

                            var lipsLowerRightOuter = annotations.lipsLowerOuter[3];
                            var lipsUpperRightOuter = annotations.lipsUpperOuter[4];

                            var lipsLowerCenterOuter = annotations.lipsLowerOuter[4];
                            var lipsUpperCenterOuter = annotations.lipsUpperOuter[5];

                            // var lipsLowerLeftInner = annotations.lipsLowerInner[5];
                            // var lipsUpperLeftInner = annotations.lipsUpperInner[6];

                            var lipsLowerRightInner = annotations.lipsLowerInner[3];
                            var lipsUpperRightInner = annotations.lipsUpperInner[4];

                            // var lipsLowerCenterInner = annotations.lipsLowerInner[4];
                            // var lipsUpperCenterInner = annotations.lipsUpperInner[5];

                            var lipsLeft = annotations.lipsUpperOuter[0];
                            var lipsRight = annotations.lipsUpperOuter[10];

                            //distance inner 
                            // var disLeftInner = getDistanceToCoordinates(
                            // lipsLowerLeftInner[0], lipsLowerLeftInner[1], lipsLowerLeftInner[2],
                            // lipsUpperLeftInner[0], lipsUpperLeftInner[1], lipsUpperLeftInner[2]);
                            var disRightInner = getDistanceToCoordinates(
                                lipsLowerRightInner[0], lipsLowerRightInner[1], lipsLowerRightInner[2],
                                lipsUpperRightInner[0], lipsUpperRightInner[1], lipsUpperRightInner[2]);
                            // var disCenterInner = getDistanceToCoordinates(
                                // lipsLowerCenterInner[0], lipsLowerCenterInner[1], lipsLowerCenterInner[2],
                                // lipsUpperCenterInner[0], lipsUpperCenterInner[1], lipsUpperCenterInner[2]);

                            // distance outer
                            var disLeftOuter = getDistanceToCoordinates(
                                lipsLowerLeftOuter[0], lipsLowerLeftOuter[1], lipsLowerLeftOuter[2],
                                lipsUpperLeftOuter[0], lipsUpperLeftOuter[1], lipsUpperLeftOuter[2]);
                            var disRightOuter = getDistanceToCoordinates(
                                lipsLowerRightOuter[0], lipsLowerRightOuter[1], lipsLowerRightOuter[2],
                                lipsUpperRightOuter[0], lipsUpperRightOuter[1], lipsUpperRightOuter[2]);
                            var disCenterOuter = getDistanceToCoordinates(
                                lipsLowerCenterOuter[0], lipsLowerCenterOuter[1], lipsLowerCenterOuter[2],
                                lipsUpperCenterOuter[0], lipsUpperCenterOuter[1], lipsUpperCenterOuter[2]);
                            var disLeftRightLip = getDistanceToCoordinates(
                                lipsLeft[0],lipsLeft[1], lipsLeft[2], 
                                lipsRight[0], lipsRight[1], lipsRight[2]);
                            if(checkStraightFace(annotations)){
                                checkFaceSmile(disLeftOuter, disRightOuter, disCenterOuter, disLeftRightLip,disRightInner);
                            }
                                
                            // var upL= (
                            //     ( annotations.lipsUpperOuter[3][0] - annotations.lipsLowerOuter[4][0] ) +
                            // ( annotations.lipsUpperOuter[3][1] - annotations.lipsLowerOuter[4][1] ) +
                            // ( annotations.lipsUpperOuter[3][2] - annotations.lipsLowerOuter[4][2] ));

                            // var downL= (
                            //     ( annotations.lipsUpperInner[0][0] - annotations.lipsUpperInner[10][0] ) +
                            // ( annotations.lipsUpperInner[0][1] - annotations.lipsUpperInner[10][1] ) +
                            // ( annotations.lipsUpperInner[0][2] - annotations.lipsUpperInner[10][2] ));
                            // var ratioLip= upL/downL;
                            // checkFaceSmile(ratioLip);

                            break;
                        case 'left':
                            timeouts.push(setTimeout(()=>{setTimer("left");}, 3000));
                            var cheekRight = annotations.rightCheek[0];
                            if (cheekRight[2] < 5 && cheekRight[2] > -5) {
                                checkFaceTurnLeftOrRight(1, 'center');
                            }
                            if (cheekRight[2] < -5) {
                                checkFaceTurnLeftOrRight(2, randomStep[runStep].name);
                            }
                            break;
                        case 'right':
                            timeouts.push(setTimeout(()=>{setTimer("right");}, 3000));
                            var cheekRight = annotations.rightCheek[0];
                            if (cheekRight[2] < 5 && cheekRight[2] > -5) {
                                checkFaceTurnLeftOrRight(1, 'center');
                            }
                            if (cheekRight[2] > 5) {
                                checkFaceTurnLeftOrRight(1, randomStep[runStep].name);
                            }
                            break;
                        case "blink":
                            timeouts.push(setTimeout(()=>{setTimer("blink");}, 3000));
                            
                            // var upR= (( annotations.rightEyeUpper1[3][0] - annotations.rightEyeLower1[4][0] ) +
                            // ( annotations.rightEyeUpper1[3][1] - annotations.rightEyeLower1[4][1] ) +
                            // ( annotations.rightEyeUpper1[3][2] - annotations.rightEyeLower1[4][2] ));
                            // var downR= (( annotations.rightEyeUpper1[0][0] - annotations.rightEyeLower1[8][0] ) +
                            // ( annotations.rightEyeUpper1[0][1] - annotations.rightEyeLower1[8][1] ) +
                            // ( annotations.rightEyeUpper1[0][2] - annotations.rightEyeLower1[8][2] ));
                            // var ratioR= upR/downR;
                            // var upL= (( annotations.leftEyeUpper1[3][0] - annotations.leftEyeLower1[4][0] ) +
                            // ( annotations.leftEyeUpper1[3][1] - annotations.leftEyeLower1[4][1] ) +
                            // ( annotations.leftEyeUpper1[3][2] - annotations.leftEyeLower1[4][2] ));
                            // var downL= (( annotations.leftEyeUpper1[0][0] - annotations.leftEyeLower1[8][0] ) +
                            // ( annotations.leftEyeUpper1[0][1] - annotations.leftEyeLower1[8][1] ) +
                            // ( annotations.leftEyeUpper1[0][2] - annotations.leftEyeLower1[8][2] ));
                            var eyeLowerLeftOuter1 = annotations.leftEyeLower0[3];
                            var eyeUpperLeftOuter1 = annotations.leftEyeUpper0[2];

                            var eyeLowerLeftOuter2 = annotations.leftEyeLower0[5];
                            var eyeUpperLeftOuter2 = annotations.leftEyeUpper0[4];

                            var eyeLowerLeftOuter = annotations.leftEyeLower0[0];
                            var eyeUpperLeftOuter = annotations.leftEyeLower0[8];

                            var disLeftOuter1 = getDistanceToCoordinates(
                                eyeLowerLeftOuter1[0], eyeLowerLeftOuter1[1], eyeLowerLeftOuter1[2],
                                eyeUpperLeftOuter1[0], eyeUpperLeftOuter1[1], eyeUpperLeftOuter1[2]);
                            
                            var disLeftOuter2 = getDistanceToCoordinates(
                                eyeLowerLeftOuter2[0], eyeLowerLeftOuter2[1], eyeLowerLeftOuter2[2],
                                eyeUpperLeftOuter2[0], eyeUpperLeftOuter2[1], eyeUpperLeftOuter2[2]);

                            var disLeftOuter = getDistanceToCoordinates(
                                eyeLowerLeftOuter[0], eyeLowerLeftOuter[1], eyeLowerLeftOuter[2],
                                eyeUpperLeftOuter[0], eyeUpperLeftOuter[1], eyeUpperLeftOuter[2]);
                            var averageL = (disLeftOuter1 + disLeftOuter2) /2;
                            var ratioL= averageL / disLeftOuter;
                            //right
                            var eyeLowerRightOuter1 = annotations.rightEyeLower0[3];
                            var eyeUpperRightOuter1 = annotations.rightEyeUpper0[2];
    
                            var eyeLowerRightOuter2 = annotations.rightEyeLower0[5];
                            var eyeUpperRightOuter2 = annotations.rightEyeUpper0[4];
    
                            var eyeLowerRightOuter = annotations.rightEyeLower0[0];
                            var eyeUpperRightOuter = annotations.rightEyeLower0[7];
    
                            var disRightOuter1 = getDistanceToCoordinates(
                                eyeLowerRightOuter1[0], eyeLowerRightOuter1[1], eyeLowerRightOuter1[2],
                                eyeUpperRightOuter1[0], eyeUpperRightOuter1[1], eyeUpperRightOuter1[2]);
                                
                            var disRightOuter2 = getDistanceToCoordinates(
                                eyeLowerRightOuter2[0], eyeLowerRightOuter2[1], eyeLowerRightOuter2[2],
                                eyeUpperRightOuter2[0], eyeUpperRightOuter2[1], eyeUpperRightOuter2[2]);
    
                            var disRightOuter = getDistanceToCoordinates(
                                eyeLowerRightOuter[0], eyeLowerRightOuter[1], eyeLowerRightOuter[2],
                                eyeUpperRightOuter[0], eyeUpperRightOuter[1], eyeUpperRightOuter[2]);
                            //
                            var averageR = (disRightOuter1 + disRightOuter2) /2;
                            var ratioR= averageR / disRightOuter;
                            if(checkStraightFace(annotations)){
                                checkEyeBlink(ratioR, ratioL);
                            }
                            break;
                        default:
                            break;
                        }
                    }
                } else {
                    resetProcess();
                    setErrorLive({ 
                        msg: "Quý khách đã rời mặt khỏi khung hình. Vui lòng thực hiện lại quá trình",
                        type: "agree"
                    });
                }
                ctx.beginPath();
            });
        } else {
            if (predictions.length == 0) {
                resetProcess();
                setErrorLive({ 
                    msg: "Quý khách đã rời mặt khỏi khung hình. Vui lòng thực hiện lại quá trình",
                    type: "agree"
                });
            } else {
                resetProcess();
                setErrorLive({ msg: ERR_MESSAGE_MANY_FACES,type: "redo"});
            }
        }
    };
    /** end detect mesh */

    const onUserMediaChange =() => {
        // runFacemesh();
    };

    const onUserMediaErrorChange =() => {
        setErrorLive({ msg: ERR_MESSAGE_NOT_ALLOW,type: "redo"});
    };

    const resetProcess = ()=>{
        // if(runStep > 0){
        // getRandomStep();
        // }
        setRunActiveStep(0);
        setValueProgress(0);
        setProgress(0);
        runStep = 0;
        setErrorTime(false);
        setTimeLeft(TOTAL_TIME);
        straightFace = {isStraight: false, coordinate:{}, isMoving:false, count:0};
        smileFace = { isSmile: false,count:0};
        blinkFace = { isBlink: false,count:0,coordinate:{}};
        checkSmile = 0;
        minLip = 0;
        checkObj = [];
    };

    const handleRetryCamera= ()=>{
        // resetProcess();
        setOpenDialog(false);
        setErrorLive({ msg: "",type: ""});
        setEnableWebcam(true);
        handleErrorLiveness();
    };

    const handleNextLiveness= ()=>{
        setInstructor(false);
    };

    return (
        !isEmpty(infoFaceComparison)  ? 
            <ResultCompareContainer 
                RANDOM_STEP={randomStep} 
                onUserMediaChange={onUserMediaChange}
                webcamRef={webcamRef} canvasRef={canvasRef} runStep={runActiveStep}
                valueProgress= {valueProgress} loadingTime={loadingTime}
                errorLive={errorLive} timer= {timer} openDialog={openDialog}
                successLive={successLive} imgLive={imgLive} handleCloseDialog={handleCloseDialog}
                onUserMediaErrorChange={onUserMediaErrorChange} errorTime={errorTime}
                handleRetry={handleRetry} enableWebcam={enableWebcam} handleRetryCamera={handleRetryCamera}
                instructor={instructor} handleNextLiveness={handleNextLiveness} {...props} 
            /> : 
            <LivenessComponent 
                randomStep={randomStep} onUserMediaChange={onUserMediaChange}
                webcamRef={webcamRef} canvasRef={canvasRef} runStep={runActiveStep}
                valueProgress= {valueProgress} loadingTime={loadingTime}
                straightFace={straightFace}
                errorLive={errorLive} timer= {timer} openDialog={openDialog}
                successLive={successLive} imgLive={imgLive} handleCloseDialog={handleCloseDialog}
                onUserMediaErrorChange={onUserMediaErrorChange} errorTime={errorTime}
                handleRetry={handleRetry} enableWebcam={enableWebcam} handleRetryCamera={handleRetryCamera}
                instructor={instructor} handleNextLiveness={handleNextLiveness} 
                progress={progress} handleClickFinish={handleClickFinish} {...props}
            /> 
    );
};

const mapStateToProps = (state) => ({
    infoCMND: state.identify.infoCMND,
    errorCMND: state.identify.errorCMND,
    isPostingCMND: state.identify.isPostingCMND,
    isPostingFaceComparison: state.face.isPostingFaceComparison,
    infoFaceComparison: state.face.infoFaceComparison,
    errorFaceComparison: state.face.errorFaceComparison,
});

const mapDispatchToProps = (dispatch) => ({
    postFaceCreators: bindActionCreators(faceActions, dispatch),
    postCMNDCreators: bindActionCreators(identifyActions, dispatch),
});

const withConnect = connect(mapStateToProps, mapDispatchToProps);
export default compose(withRouter, withConnect)(LivenessContainer);