/* eslint-disable react/prop-types */
import React from 'react';
import { useStyles, BootstrapButton } from './styles';
import {Typography} from '@material-ui/core';

function ItemNotice(props) {
    const classes = useStyles();
    const { alt, image, title } = props;
    return (
        <div className={classes.paperInstructorNew}>
            <img alt={alt} src={image} className={classes.imgIconNew}/>
            <Typography className={classes?.txtTitle}>{title}</Typography>
        </div>
    );
}

export default ItemNotice;
