import { makeStyles, withStyles, styled } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from '@material-ui/core/Dialog';
import { colors } from "constants/colors";

export const useStyles = makeStyles((theme) => ({
    cameraContainer: {
        display: "flex",
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%"
    },

    cameraDiv: {
        display: "flex",
        flexDirection: 'column',
        top: 0,
        right: 0,
        bottom: 0,
        left: 0,
        width: "100%",
        height: "100%",
        backgroundColor: colors?.backgroundB2
    },
    cameraPaper: {
        width: "100%",
        minHeight: "284px",
        marginBottom: 15
    },
    img: {
        width: "100%",
        height: "100%"
    },
    btnNext: {
        borderRadius: "6px",
        color: "white",
        fontWeight: "bold",
        backgroundColor: colors?.primaryP2,
        "&:hover": {
            backgroundColor: "#A2C1FA",
        },
        marginTop: 0,
        padding: '14px 54px'
    },
    textInstruction: {
        fontFamily: "Roboto",
        textAlign: 'center',
    },
    headerInstruction: {
        fontFamily: "Roboto",
        textAlign: 'center',
        fontWeight: 'bold',
    },
    textTitle: {
        fontFamily: "Roboto",
        fontWeight: "bold",
        padding: 15,
        paddingBottom: 0,
        // borderBottom: '0.5px solid #DADBDD',
        color: colors?.textT10
    },
    textTitleKey: {
        fontFamily: "Roboto",
        textAlign: 'center',
        color: colors?.textT10
    },
    textIdentify: {
        fontFamily: "Roboto",
        fontWeight: "bold",
        padding: 15,
        color: 'white',
        // borderWidth: 0,
        // borderLeftWidth: 1,
        // borderRightWidth: 1,
        // borderStyle: 'solid', borderColor: '#DADBDD'
    },
    paperShow: {
        height: "92%",
        backgroundColor: "#E7E7E7",
        marginTop: 20,
        marginRight: 20,
        marginBottom: 20,
        [theme.breakpoints.down('sm')]: {
            height: "92%",
            marginRight: 5,
            marginBottom: 5,
        },
    },
    divShow: {
        flex: 3,
        height: "100%",
        borderBottom: "1px solid #CACACA",
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    divShowLast: {
        flex: 3,
        height: "100%",
        width: "100%",
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column'
    },
    textShow: {
        fontFamily: "Roboto",
        textAlign: 'center',
        paddingTop: 10,
        paddingBottom: 0,
        color: '#3B3B3B',
        fontWeight: 'bold',
        [theme.breakpoints.down('sm')]: {
            paddingTop: 15,
            paddingBottom: 10,
        },
        [theme.breakpoints.down('xs')]: {
            paddingTop: 15,
            paddingBottom: 10,
        },
    },
    imgShow: {
        width: '100%',
        resizeMode: 'cover',
        height: '100%',
        margin: '15px 0px 20px 0px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        [theme.breakpoints.down('sm')]: {
            padding: 0,
            margin: 0,
            marginBottom: 15
        },
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            margin: 0,
            marginBottom: 15
        },
    },
    containerConstructor: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(7),
    },
    containerImg: {
        display: 'flex',
        justifyContent: 'center',
        marginBottom: theme.spacing(4),
    },
    detailInstructor: {
        display: 'flex',
        justifyContent: 'center',
        margin: theme.spacing(7),
    },
    btnCompare: {
        marginLeft: 20,
        marginRight: 20,
        borderRadius: "10px",
        color: colors?.primaryP2,
        backgroundColor: "#F2F7FE",
        fontSize: "24px"
    },
    btnBack: {
        marginTop: 20,
        marginRight: 10,
        borderRadius: "6px",
        color: "white",
        fontWeight: "bold",
        backgroundColor: "#B8B8B8",
        "&:hover": {
            backgroundColor: "#A2C1FA",
        },
        padding: '10px 44px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 10,
            padding: '5px 24px',
        }
    },
    btnFinish: {
        marginTop: 20,
        marginLeft: 10,
        borderRadius: "6px",
        color: "white",
        fontWeight: "bold",
        backgroundColor: colors?.primaryP2,
        "&:hover": {
            backgroundColor: "#A2C1FA",
        },
        padding: '10px 44px',
        [theme.breakpoints.down('sm')]: {
            marginTop: 10,
            padding: '5px 24px',
        }
    },
    textField: {
        paddingBottom: 10,
        "& .MuiFilledInput-input": {
            color: "#1665D8",
            fontSize: '18px',
            [theme.breakpoints.down('xs')]: {
                fontSize: '14px',
            }
        },
        "& .MuiFilledInput-root": {
            backgroundColor: "#FFFFFF",
            borderRadius: 6
        },
    },
    divImg: {
        maxWidth: '251px',
        maxHeight: '165px',
        alignItems: 'center',
        display: 'flex',
        justifyContent: 'center',
    },
    dialog: {
        '& .MuiDialog-paper': {
            borderRadius: "10px !important"
        }
    },
    dialogContent: {
        padding: 40
    },
    divCancelIcon: {
        position: 'absolute',
        right: 15,
        marginTop: -20,
        cursor: 'pointer'
    },
    cancelIcon: {
        width: 33,
        height: 33,
        backgroundColor: '#ffffff',
        borderRadius: 30,
        position: 'fixed'
    },
    dialogPaper: {
        backgroundColor: "#FFFFFF",
        paddingTop: 30,
        paddingBottom: 30,
        paddingRight: 150,
        paddingLeft: 150,
        [theme.breakpoints.down('sm')]: {
            paddingRight: 100,
            paddingLeft: 100,
        }
    },
    dialogGrid: {
        alignItems: "center",
        textAlign: 'center',
    },
    headerError: {
        color: "#121212",
        fontFamily: "Roboto",
        fontWeight: "bold",
    },
    textError: {
        fontFamily: "Roboto",
        color: "#706F6F",
        marginTop: 10,
        marginBottom: 10,
    },
    btnRedo: {
        borderRadius: "6px",
        color: "white",
        fontWeight: "bold",
        backgroundColor: colors?.primaryP2,
        "&:hover": {
            backgroundColor: "#A2C1FA",
        },
        marginTop: 20,
        marginBottom: 20,
        padding: '15px 54px',
        [theme.breakpoints.down('sm')]: {
            padding: '5px 24px',
        }
    },
    divRowImage: {
        width: '100%',
        display: 'flex', flexDirection: 'row',
        justifyContent: 'center',
        paddingLeft: 20, paddingRight: 20, paddingTop: 25,
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0, paddingRight: 0,
            justifyContent: 'space-evenly',

        }
    },
    gridItemFirst: {
        height: '100%',
        marginLeft: '45px !important',
        marginRight: '45px !important',
        [theme.breakpoints.down('md')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        }
    },
    gridItemSecondNone: {
        // marginLeft:'150px !important', 
        [theme.breakpoints.down('md')]: {
            display: 'none',
            marginRight: '30px !important',
            marginLeft: '30px !important',
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        }
    },
    gridItemSecond: {
        // marginLeft:'150px !important', 
        [theme.breakpoints.down('md')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        },
        [theme.breakpoints.down('sm')]: {
            marginRight: '30px !important',
            marginLeft: '30px !important',
        }
    },
    divSecond: {
        marginTop: 20,
        marginBottom: 20,
        backgroundColor: '#E7E7E7',
        borderRadius: '10px',
    },
    paperEmpty: {
        height: '165px',
        backgroundColor: '#E7E7E7',
    },
    paperImg: {
        width: "179",
        height: "178",
        [theme.breakpoints.down('xs')]: {
            width: "146px",
            height: "172px",
        }
    },
    imgLive: {
        width: 179,
        height: 178,
        resize: 'contain',
        [theme.breakpoints.down('xs')]: {
            width: 115,
            height: 132,
            resize: 'contain',
        }
    },
    btnFinishLive: {
        borderRadius: "6px",
        color: "white",
        fontWeight: "bold",
        backgroundColor: colors?.primaryP2,
        "&:hover": {
            backgroundColor: "#A2C1FA",
        },
        padding: '10px 44px',
        marginBottom: 50,
        [theme.breakpoints.down('sm')]: {
            padding: '5px 24px',
            marginBottom: 20,
        }
    },
    img1: {
        display: "flex",
        flexDirection: "column",
        marginRight: 32,
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
        }
    },
    img2: {
        display: "flex",
        flexDirection: "column",
        [theme.breakpoints.down('sm')]: {
            marginRight: 0
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
        }
    },
    divImgError: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    flexImgError: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#FDE7E6",
        paddingTop: 35,
        paddingBottom: 35,
        marginTop: 30,
        marginBottom: 30,
        width: "90%",
        maxWidth: 430,
        [theme.breakpoints.down('md')]: {
            marginLeft: 20,
            marginRight: 20,
        }
    },
    flexImgSuccess: {
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        backgroundColor: "#D6F6E4",
        paddingTop: 35,
        paddingBottom: 35,
        marginTop: 30,
        marginBottom: 30,
        width: "90%",
        maxWidth: 430,
        [theme.breakpoints.down('md')]: {
            marginLeft: 20,
            marginRight: 20,
        }
    },
    imgResult: {
        width: "100%",
        height: "100%",
    },
    paperFirst: {
        // border: '1px solid #DADBDD',
        height: "94%",
        marginTop: 20,
        marginBottom: 10,
        [theme.breakpoints.down('sm')]: {
            height: "98%",
        }
    },
    containerSecond: {
        display: "flex",
        justifyContent: "center"
    },
    divText: {
        flex: 1,
        width: "75%",
        [theme.breakpoints.down('xs')]: {
            width: "100%",
        }
    },
    textErrorCompare: {
        textAlign: 'center',
        fontWeight: "bold"
    },
    divText1: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        paddingBottom: 10,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 10,
            justifyContent: "center",
            alignItems: "center",
        }
    },
    divText2: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        paddingBottom: 10,
        [theme.breakpoints.down('xs')]: {
            paddingTop: 10,
            justifyContent: "center",
            alignItems: "center",
        }
    },
    divPaper1: {
        width: '199px',
        height: '234px',
        display: "flex",
        alignItems: 'center',
        justifyContent: "center",
        borderWidth: 1, borderStyle: 'solid',
        borderColor: '#898989',
        borderRadius: 5,
        [theme.breakpoints.down('xs')]: {
            height: '153px',
            width: '130px',
            justifyContent: "center",
        }
    },
    divPaper2: {
        width: '199px',
        height: '234px',
        alignItems: 'center',
        display: "flex",
        justifyContent: "center",
        borderWidth: 1, borderStyle: 'solid',
        borderColor: '#898989',
        borderRadius: 5,
        [theme.breakpoints.down('xs')]: {
            height: '153px',
            width: '130px',
            justifyContent: "center",
        }
    },
    textFieldContainer: {
        paddingLeft: 20,
        paddingRight: 20,
        paddingTop: 20,
    },
}));

export const BootstrapButton = withStyles({
    root: {
        textTransform: "none",
        fontFamily: [
            "-apple-system",
            "BlinkMacSystemFont",
            '"Segoe UI"',
            "Roboto",
            '"Helvetica Neue"',
            "Arial",
            "sans-serif",
            '"Apple Color Emoji"',
            '"Segoe UI Emoji"',
            '"Segoe UI Symbol"',
        ].join(","),
    },
})(Button);

export const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuDialogContent-root': {
        padding: theme.spacing(2),
    },
    '& .MuDialogActions-root': {
        padding: theme.spacing(1),
    },
}));
